import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TableComponentModule } from '../shared/ui/table.component'
import { fetchAuthSession } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import awsExports from '../../../../aws-exports';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CognitoService } from 'src/app/dmp/services/cognito.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { MobileApp } from 'src/app/dmp/api/mobile-app';
import { MobileAppService } from 'src/app/dmp/services/mobile-app.service';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { Table } from 'primeng/table';
import { ViewEncapsulation} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MessagesModule } from 'primeng/messages';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { DmpCommonsComponent } from '../shared/dmp-commons/dmp-commons.component';
import { take, timer } from 'rxjs';

interface expandedRows {
  [key: string]: boolean;
}

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.css', '../shared/dmp-commons/dmp-commons.component.css'],
  providers: [MessageService, ConfirmationService, MobileAppService, DmpCommonsComponent]
})

export class MobileAppComponent implements OnInit {
  removableApps: number = 0;
  refreshTimerStartDueInMilliseconds: number = 2000;
  refreshTimerStartIntervalDurationInMilliseconds: number = 2000;
  refreshTimerTimesToRefresh: number = 1;

  mustHideButtonUninstallApp(): boolean {
    return !this.selectedAppId
     || this.processingUninstallApp
     || this.selectedAppRemovedStatus
     ;
  }//end mustHideButtonUninstallApp

  mustHideButtonDisableApp(): boolean {
    return !this.selectedAppId
     || this.processingDisableApp
     || this.selectedAppDisabledStatus
     ;
  }//end mustHideButtonDisableApp

  mustHideButtonReenableApp(): boolean {
    return !this.selectedAppId
     || this.processingReenableApp
     || !this.selectedAppDisabledStatus
     ;
  }//end mustHideButtonReenableApp

  mustHideButtonRemoveAllApps(): boolean {
    //console.log("this.removableApps: " + this.removableApps);
    return this.processingRemoveAllApps || this.removableApps == 0;
  }//end mustHideButtonRemoveAllApps

  messagesVisibility: string = "none";

  //#region Table Properties
  emptyTableMessage: string = "";

  searchText: string = "";

  apps: MobileApp[] = [];

  rowGroupMetadata: any;

  expandedRows: expandedRows = {};

  activityValues: number[] = [0, 100];

  isExpanded: boolean = false;

  idFrozen: boolean = false;

  loading: boolean = true;

  @ViewChild('filter') filter!: ElementRef;

  selectedAppId: any;
  selectedAppDisplayName: any;
  selectedAppState: any;
  selectedAppDisabledStatus: any;
  selectedAppRemovedStatus: any;

    @ViewChild('table') table: Table
  //#endregion

  authService = null;

  labelRemoveAllApps: string = "Remove All Apps";
  labelRemoveAllAppsOriginal: string = this.labelRemoveAllApps;
  processingRemoveAllApps: boolean = false;

  labelUninstallApp: string = "Remove App";
  labelUninstallAppOriginal: string = this.labelUninstallApp;
  processingUninstallApp: boolean = false;

  labelDisableApp: string = "Disable App";
  labelDisableAppOriginal: string = this.labelDisableApp;
  processingDisableApp: boolean = false;

  labelReenableApp: string = "Reactivate App";
  labelReenableAppOriginal: string = this.labelReenableApp;
  processingReenableApp: boolean = false;

  constructor
  ( 
  public dmpCommons: DmpCommonsComponent,
  private messageService: MessageService, 
  private activatedRoute: ActivatedRoute,
  private router:Router, 
  private cognitoService: CognitoService,
  private mobileAppService: MobileAppService,
  public authenticator: AuthenticatorService)
  { 
    Amplify.configure(awsExports);

    this.authenticator = authenticator;
  }//end constructor

  async currentSession() {
        
    try {
        let authSessionTokens = (await fetchAuthSession()).tokens ?? {};
        
        return authSessionTokens;
    } catch (err) {
        console.log(err);
    }
    
    return undefined;
  }

  enterpriseId?:string;
  deviceId?:string;
  emmDeviceId?:string;
  policyId?:string;

  ngOnInit() {
    //#region params

    this.enterpriseId = this.activatedRoute.snapshot.paramMap.get('enterpriseId') ?? undefined;
    this.emmDeviceId = this.activatedRoute.snapshot.paramMap.get('emmDeviceId') ?? undefined;
    this.policyId = this.activatedRoute.snapshot.paramMap.get('policyId') ?? undefined;
    this.deviceId = this.activatedRoute.snapshot.paramMap.get('deviceId') ?? undefined;

    console.log("mobile-app: this.enterpriseId: " + this.enterpriseId);
    console.log("mobile-app: this.emmDeviceId: " + this.emmDeviceId);
    console.log("mobile-app: this.policyId: " + this.policyId);
    console.log("mobile-app: this.deviceId: " + this.deviceId);
    //#endregion
    
    //#region service data
    this.loadTable();
    //#endregion

  }//end ngOnInit

  loadTable() {
    if (this.enterpriseId == undefined) {
      console.log("enterpriseId is undefined");
      return;
    }

    if (this.deviceId == undefined) {
      console.log("deviceId is undefined");
      return;
    }
    
    this.currentSession().then((authSessionTokens: any) => {
      this.clearSelection();
      this.emptyTableMessage = "Loading...";
      let idToken:string = authSessionTokens.idToken.toString();
      
      let enterpriseId = this.enterpriseId ?? '';
      let deviceId = this.deviceId ?? '';
      console.log(`Listing apps from enterprise ${enterpriseId}, device ${deviceId}`);

      this.mobileAppService.getApps(idToken, enterpriseId, deviceId).subscribe({
        next: mobileAppResponse => {
          //to-do: testar retorno de warn, err, info
          this.messagesVisibility = "block";
          let responseHasError = this.dmpCommons.handleResponseWithMessage(mobileAppResponse, undefined, undefined, true, this.messageService);
          this.removableApps = 0;
          //console.log("this.apps.length: " + this.apps.length);
          if (responseHasError) {
            this.emptyTableMessage = "Load failed, try again later...";
          }else{
            this.apps = mobileAppResponse.data;
            if (this.apps.length == 0) {
              this.emptyTableMessage = "No data found";
            }else{
              let appItau = this.apps.find(app => app.packageName === 'com.itau');
              if (appItau) {
                console.log("selecting appItau: " + appItau);
                const event = new MouseEvent('click');
                this.table.selfClick = true;
                //this.selectApp(appItau);
              }
              this.removableApps = this.apps.filter(app => app.state === 'INSTALLED').length;
            }
          }
        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Error listing apps, try again later' });
          console.error('Error fetching apps:', error);
        }
      });

    })
    .catch(error => {
      
        this.emptyTableMessage = "Load failed, try again later...";
        this.messagesVisibility = "block";
        this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Error fetching session to list apps, try again later' });
        console.error('Error fetching currentSession():', error);
    });
  }//end loadTable

  clearSelection() {
    this.selectedAppId = undefined;
    this.selectedAppDisabledStatus = undefined;
    this.selectedAppRemovedStatus = undefined;
    this.selectedAppDisplayName = undefined;
    this.selectedAppState = undefined;

    this.processingDisableApp = false;
    this.processingReenableApp = false;
    this.processingRemoveAllApps = false;
    this.processingUninstallApp = false;
  }


  //#endregion

  //#region cognito
  forceSignOut () {
    this.authenticator.signOut();
    this.router.navigate(['/sign-in']);
  }
  
  //#endregion

  //#region Table Methods
  onRowSelect(event: any) { 
    console.info("onRowSelect event: ", event);
    console.info("onRowSelect event string: ", event.toString());
    this.selectApp(event.data);
  } 

  private selectApp(app: any) {
    this.selectedAppId = app.packageName;
    this.selectedAppDisplayName = app.displayName;
    this.selectedAppState = app.state;
    this.selectedAppDisabledStatus = app.disabled;
    this.selectedAppRemovedStatus = (this.selectedAppState == "REMOVED" || this.selectedAppState == "REMOVAL REQUESTED");
    console.log("onRowSelect event: app.packageName: " + app.packageName);
  }

  onRowUnselect(event: any) { 
    this.selectedAppId = undefined;
    console.log("onRowUnselect event");
  }

  formatCurrency(value: number) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  onGlobalFilter(table: Table, event: Event) {
      this.emptyTableMessage = "No results for current filter...";
      table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
      table.clear();
      this.removableApps = 0;
      this.filter.nativeElement.value = '';
  }

  handleClickRefresh(event: any) {
    this.onRowUnselect(event);

    this.refreshTable();
  }

  private refreshTable() {
    this.clearSelection();
    this.emptyTableMessage = "Refreshing...";
    this.filter.nativeElement.value = "";
    this.searchText = "";
    this.removableApps = 0;
    this.table.clear();

    this.apps = [];

    setTimeout(() => {
      this.loadTable();
    }, 2000);
  }

  handleClickAddApp(event: any) {
    console.log("handleClickAddApp event: ");
    this.router.navigate(['/home/mobile-app-install', this.enterpriseId, this.deviceId, this.policyId]);

  }

  handleClickRemoveAllApps(event: any) {
    this.removeAllApps();
  }

  handleClickUninstallApp(event: any) {
    let justDisable: boolean = false;
    this.removeApp(justDisable);
  }

  handleClickDisableApp(event: any) {
    let justDisable: boolean = true;
    this.removeApp(justDisable);
  }

  handleClickReenableApp(event: any) {
    this.reenableApp();
  }

  private reenableApp() {
    if (this.selectedAppId == null || this.selectedAppId == undefined) {
      console.log("reenableApp event: App is undefined: select one");
      return;
    }
    console.log("reenableApp event: " + this.selectedAppId);

    let enterpriseId = this.enterpriseId ?? '';
    let policyId = this.policyId ?? '';
    let deviceId = this.deviceId ?? '';
    let packageName = this.selectedAppId;
    let displayName = this.selectedAppDisplayName;

    console.log(`Reenabling app ${packageName} from enterprise ${enterpriseId}, policy ${policyId}, device ${deviceId}`);

    this.reenableAppService(enterpriseId, policyId, deviceId, packageName);
  }

  reenableAppService(enterpriseId: string, policyId: string, deviceId: string, packageName: string) {
    this.setButtonReenableAppProcessingStatus();

    this.currentSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();

      this.mobileAppService.reenableApp(idToken, enterpriseId, policyId, deviceId, packageName).subscribe({
        next: reenableAppResponse => {
          this.messagesVisibility = "block";
          let successMessage;
          successMessage = `App ${this.selectedAppDisplayName} re-activated !`;

          let responseHasError = this.dmpCommons.handleResponseWithMessage(reenableAppResponse, successMessage, 'Operation will be finished in a few seconds.', true, this.messageService);

          if (!responseHasError) {
            //Refresh n times, every x seconds, starting in y seconds
            //n = refreshTimerTimesToRefresh
            //x = refreshTimerStartIntervalDurationInMilliseconds
            //y = refreshTimerStartDueInMilliseconds
            timer(this.refreshTimerStartDueInMilliseconds, this.refreshTimerStartIntervalDurationInMilliseconds).pipe(
              take(this.refreshTimerTimesToRefresh)).subscribe(() => {
                this.refreshTable();
            });
          }//end if

          //On success, the response from google is empty, (http 200 on success)
          //this.messageService.add({ key: 'shortMessages', severity: 'success', summary: 'Uninstall Success !', detail: 'It will be done in a few seconds.'});
          //console.log(`App ${packageName} deleted successfully. enterpriseId: ${enterpriseId}, policyId: ${policyId}`);
          this.enableButtonReenableApp();

          console.log(`reenableAppResponse: ${reenableAppResponse}`);
        },
        error: error => {
          // Handle error if needed
          console.error('Error re-enabling app:', error);
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Operation Failed, try again in few minutes' });
          console.log(`error: ${error}`);

          this.enableButtonReenableApp();
        }
      });
  
    })
    .catch(error => {
        this.messagesVisibility = "block";
        this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Error fetching session to proccess operation' });
        console.error('Error fetching currentSession() for removingg apps:', error);
        this.enableButtonUninstallApp();
    });
  }//end deleteApp

  private removeApp(justDisable: boolean) {
    if (this.selectedAppId == null || this.selectedAppId == undefined) {
      console.log("removeApp event: App is undefined: select one");
      return;
    }
    console.log("removeApp event: " + this.selectedAppId);

    let enterpriseId = this.enterpriseId ?? '';
    let policyId = this.policyId ?? '';
    let deviceId = this.deviceId ?? '';
    let packageName = this.selectedAppId;
    let displayName = this.selectedAppDisplayName;

    console.log(`Deleting (justDisable: ${justDisable}) app ${packageName} from enterprise ${enterpriseId}, policy ${policyId}, device ${deviceId}`);

    this.deleteAppService(enterpriseId, policyId, deviceId, packageName, displayName, justDisable);
  }

  private removeAllApps() {
    let enterpriseId = this.enterpriseId ?? '';
    let policyId = this.policyId ?? '';
    let deviceId = this.deviceId ?? '';
    let packageName = "*";

    console.log(`Deleting (Removing all apps from ${enterpriseId}, policy ${policyId}, device ${deviceId}`);

    this.removeAllAppsService(enterpriseId, policyId, deviceId, packageName);
  }//end removeAllApps

  //#endregion

  removeAllAppsService(enterpriseId: string, policyId: string, deviceId: string, packageName: string) {
    this.setButtonRemoveAllAppsProcessingStatus();

    this.currentSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();

      this.mobileAppService.removeAllApps(idToken, enterpriseId, policyId, deviceId, packageName).subscribe({
        next: removeAllAppsResponse => {
          this.messagesVisibility = "block";
          let successMessage;
          successMessage = `All apps removed !`;

          let responseHasError = this.dmpCommons.handleResponseWithMessage(removeAllAppsResponse, successMessage, 'Operation will be finished in a few seconds.', true, this.messageService);

          if (!responseHasError) {
            //Refresh n times, every x seconds, starting in y seconds
            //n = refreshTimerTimesToRefresh
            //x = refreshTimerStartIntervalDurationInMilliseconds
            //y = refreshTimerStartDueInMilliseconds
            timer(this.refreshTimerStartDueInMilliseconds, this.refreshTimerStartIntervalDurationInMilliseconds).pipe(
              take(this.refreshTimerTimesToRefresh)).subscribe(() => {
                this.refreshTable();
            });
          }//end if

          //On success, the response from google is empty, (http 200 on success)
          //this.messageService.add({ key: 'shortMessages', severity: 'success', summary: 'Uninstall Success !', detail: 'It will be done in a few seconds.'});
          //console.log(`App ${packageName} deleted successfully. enterpriseId: ${enterpriseId}, policyId: ${policyId}`);
          this.enableButtonRemoveAllApps();

          console.log(`removeAllAppsResponse: ${removeAllAppsResponse}`);
        },
        error: error => {
          // Handle error if needed
          console.error('Error removing apps:', error);
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Operation Failed, try again in few minutes' });
          console.log(`error: ${error}`);

          this.enableButtonUninstallApp();
        }
      });
  
    })
    .catch(error => {
        this.messagesVisibility = "block";
        this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Error fetching session to proccess operation' });
        console.error('Error fetching currentSession() for removingg apps:', error);
        this.enableButtonUninstallApp();
    });
  }//end deleteApp

 
  deleteAppService(enterpriseId: string, policyId: string, deviceId: string, packageName: string, displayName: string, justDisable: boolean) {
    if (justDisable) {
      this.setButtonDisableAppProcessingStatus();
    }else{
      this.setButtonUninstallAppProcessingStatus();
    }

    this.currentSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();

      this.mobileAppService.deleteApp(idToken, enterpriseId, policyId, deviceId, packageName, justDisable).subscribe({
        next: deleteAppResponse => {
          //this.dmpCommons.handleResponse(deleteAppResponse, 'Uninstall Success !', 'It will be done in a few seconds.', true);
          this.messagesVisibility = "block";
          let successMessage;
          if (justDisable) {
            successMessage = `App "${displayName}" has been successfully disabled !`;
          }else{
            successMessage = `App "${displayName}" has been successfully removed !`;
          }

          let responseHasError = this.dmpCommons.handleResponseWithMessage(deleteAppResponse, successMessage, 'Operation will be finished in a few seconds.', true, this.messageService);

          if (!responseHasError) {
            //Refresh n times, every x seconds, starting in y seconds
            //n = refreshTimerTimesToRefresh
            //x = refreshTimerStartIntervalDurationInMilliseconds
            //y = refreshTimerStartDueInMilliseconds
            timer(this.refreshTimerStartDueInMilliseconds, this.refreshTimerStartIntervalDurationInMilliseconds).pipe(
              take(this.refreshTimerTimesToRefresh)).subscribe(() => {
                this.refreshTable();
            });
          }//end if

          //On success, the response from google is empty, (http 200 on success)
          //this.messageService.add({ key: 'shortMessages', severity: 'success', summary: 'Uninstall Success !', detail: 'It will be done in a few seconds.'});
          //console.log(`App ${packageName} deleted successfully. enterpriseId: ${enterpriseId}, policyId: ${policyId}`);
          if (justDisable) {
            this.enableButtonDisableApp();
          }else{
            this.enableButtonUninstallApp();
          }
          console.log(`deleteAppResponse: ${deleteAppResponse}`);
        },
        error: error => {
          // Handle error if needed
          console.error('Error removing app:', error);
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Operation Failed, try again in few minutes' });
          console.log(`error: ${error}`);

          this.enableButtonUninstallApp();
        }
      });
  
    })
    .catch(error => {
        this.messagesVisibility = "block";
        this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Error fetching session to proccess app' });
        console.error('Error fetching currentSession() to deleting enterprise:', error);
        this.enableButtonUninstallApp();
    });
  }//end deleteApp

  
  private setButtonRemoveAllAppsProcessingStatus() {
    this.processingRemoveAllApps = true;
    this.labelRemoveAllApps = "Processing...";

    timer(10000).subscribe(() => {
      this.enableButtonRemoveAllApps();
      console.log("this.labelRemoveAllApps = this.labelRemoveAllAppsOriginal;");
    });
  }//end function

  private setButtonUninstallAppProcessingStatus() {
    this.processingUninstallApp = true;
    this.labelUninstallApp = "Processing...";

    timer(10000).subscribe(() => {
      this.enableButtonUninstallApp();
      console.log("this.labelUninstallApp = this.labelUninstallAppOriginal;");
    });
  }//end function

  private setButtonDisableAppProcessingStatus() {
    this.processingDisableApp = true;
    this.labelDisableApp = "Processing...";

    timer(10000).subscribe(() => {
      this.enableButtonDisableApp();
      console.log("this.labelDisableApp = this.labelDisableAppOriginal;");
    });
  }//end function

  private setButtonReenableAppProcessingStatus() {
    this.processingReenableApp = true;
    this.labelReenableApp = "Processing...";

    timer(10000).subscribe(() => {
      this.enableButtonReenableApp();
      console.log("this.labelReenableApp = this.labelReenableAppOriginal;");
    });
  }//end function

  private enableButtonUninstallApp() {
    this.labelUninstallApp = this.labelUninstallAppOriginal;
    this.processingUninstallApp = false;
  }//end function

  private enableButtonRemoveAllApps() {
    this.labelRemoveAllApps = this.labelRemoveAllAppsOriginal;
    this.processingRemoveAllApps = false;
  }//end function

  private enableButtonDisableApp() {
    this.labelDisableApp = this.labelDisableAppOriginal;
    this.processingDisableApp = false;
  }//end function

  private enableButtonReenableApp() {
    this.labelReenableApp = this.labelReenableAppOriginal;
    this.processingReenableApp = false;
  }//end function

}//end class

/*
@NgModule({
  declarations: [MobileAppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    DmpCommonsComponent,
    ToastModule,
    SharedModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    CommonModule,
    ButtonModule,
    MessagesModule,
    FieldsetModule,
    TableComponentModule,
    RouterModule.forChild([
      {
        path: '',
        component: MobileAppComponent,
      },
    ]),
  ],
})

export class DeviceModule {}
*/