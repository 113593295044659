
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { SkeletonModule } from 'primeng/skeleton';
import { DataView } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import {Component} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {SelectItem} from 'primeng/api';
import {SelectItemGroup} from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TableComponentModule } from '../shared/ui/table.component'
//import { fetchAuthSession } from 'aws-amplify/auth';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CognitoService } from 'src/app/dmp/services/cognito.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';

import { Country } from 'src/app/dmp/api/country';
import { GooglePlayCountryService } from 'src/app/dmp/services/mobile-google-play-country.service';

import { Language } from 'src/app/dmp/api/language';
import { GooglePlayLanguageService } from 'src/app/dmp/services/mobile-google-play-language.service';

import { MobileAppInstall } from 'src/app/dmp/api/mobile-app-install';
import { MobileAppInstallService } from 'src/app/dmp/services/mobile-app-install.service';

import { ConfigService } from 'src/app/dmp/services/config.service';

import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { Table } from 'primeng/table';
import { ViewEncapsulation} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MessagesModule } from 'primeng/messages';
import { FieldsetModule } from 'primeng/fieldset';
import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { DmpCommonsComponent } from '../shared/dmp-commons/dmp-commons.component';

interface expandedRows {
  [key: string]: boolean;
}

@Component({
  selector: 'app-mobile-app-install',
  templateUrl: './mobile-app-install.component.html',
  styleUrls: ['./mobile-app-install.component.css'],
  providers: [ConfigService, MessageService, ConfirmationService, GooglePlayCountryService, GooglePlayLanguageService, MobileAppInstallService, DmpCommonsComponent, MobileAppInstallService]
})

export class MobileAppInstallComponent implements OnInit {

  carouselResponsiveOptions: any[] = [
    {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
    }
  ];

  modalInstallVisible: boolean = false;
  //#region Table Properties
  emptyTableMessage: string = "Use search box to find apps";

  searchText: string = "";

  testeApps: MobileAppInstall[] = [];
  googleApps: MobileAppInstall[] = [];
  googleAppsSearchLimit: number = 13; //Above that will show a warning to refine search

  countries: Country[] = []; 
  private _selectedCountry: Country | null = null;
  defaultCountry = "{\"fullName\": \"Brazil (BR)\", \"name\": \"Brazil\", \"code\": \"BR\"}";

  languages: Language[] = [];
    private _selectedLanguage: Language | null = null;
  defaultLanguage = "{\"fullName\": \"Portuguese (pt)\", \"name\": \"Portuguese\", \"code\": \"pt\"}";

  rowGroupMetadata: any;
  expandedRows: expandedRows = {};
  activityValues: number[] = [0, 100];
  isExpanded: boolean = false;
  idFrozen: boolean = false;
  loading: boolean = true;

  @ViewChild('filter') filter!: ElementRef;
  selectedGoogleAppId: any;

  @ViewChild('tableGoogleAppsRaw') table!: Table;
  //#endregion

  authService = null;
  googleAppImages: string[];
  currentGoogleApp: MobileAppInstall;
  //username: string;

  constructor
  ( 
  private config: PrimeNGConfig,
  private translateService: TranslateService,
  public dmpCommons: DmpCommonsComponent,
  private messageService: MessageService, 
  private activatedRoute: ActivatedRoute,
  private router:Router, 
  private cognitoService: CognitoService,
  private configService: ConfigService,
  private mobileAppInstallService: MobileAppInstallService,
  private googlePlayCountryService: GooglePlayCountryService,
  private googlePlayLanguageService: GooglePlayLanguageService,
  private skeletonModule: SkeletonModule,
  public authenticator: AuthenticatorService
  )
  {
    this.translateService.setDefaultLang('en');
   
  
  }//end constructor

  emptyMessage: string = "no items found";
  enterpriseId?:string;
  deviceId?:string;
  policyId?:string;

  ngOnInit() {
    this.translateService.get('emptyMessage').subscribe((res: string) => {
      this.emptyMessage = res;
    });
    /*
    this.config.setTranslation({
        emptyFilterMessage: '...',
        //translations
    });
    */


    this.testeApps.push({appId: "com.google.android.apps.maps", title: "Google Maps", price: "0.0", currency: "USD", score: "4.5", descriptionHTML: "teste", genre: "test"  });

    this.testeApps.push({appId: "com.google.android.apps.maps", title: "Google Maps", price: "0.0", currency: "USD", score: "4.5", descriptionHTML: "teste", genre: "test"  });

    this.testeApps.push({appId: "com.google.android.apps.maps", title: "Google Maps", price: "0.0", currency: "USD", score: "4.5", descriptionHTML: "teste", genre: "test"  });
    console.log("this.testeApps.length: " + this.testeApps.length);

    //TESTE
    //this.searchGooglePlay("Banco do Brasil");

    //#region params
    this.enterpriseId = this.activatedRoute.snapshot.paramMap.get('enterpriseId') ?? undefined;
    this.deviceId = this.activatedRoute.snapshot.paramMap.get('deviceId') ?? undefined;
    this.policyId = this.activatedRoute.snapshot.paramMap.get('policyId') ?? undefined;
    
    console.log("this.enterpriseId: " + this.enterpriseId);
    console.log("this.deviceId: " + this.deviceId);
    //#endregion
    
    //#region service data
    this.loadCountries();
    this.loadLanguages();

    this.getLocalConfigValueCountry(this.defaultCountry); //First try to get from local storage
    if ( this.selectedCountry == undefined ) {
      //It means that is not on local storage, so get from service api
      this.getConfigValueCountry(this.defaultCountry);
    }

    this.getLocalConfigValueLanguage(this.defaultLanguage); //First try to get from local storage
    if ( this.selectedLanguage == undefined ) {
      //It means that is not on local storage, so get from service api
      this.getConfigValueLanguage(this.defaultLanguage);
    }

    //#endregion
  }

  googleAppFormatPriceTag(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let priceTag = "";

    if (googleApp.price == "0") {
      priceTag = "Free";
    }else{
      priceTag = `$ ${googleApp.price} ${googleApp.currency}`;
    }

    return priceTag;
  }


  handleClickInstall($event: MouseEvent, googleApp: MobileAppInstall) {
    let deviceId = this.deviceId ?? "";
    let packageName = googleApp.appId ?? "";
    let appTitle = googleApp.title ?? "";
    this.installAppService(deviceId, packageName, appTitle);
  }

  installAppService(deviceId: string, packageName: string, appTitle: string) {
    console.log(`installAppService(${deviceId}, ${packageName})`);

    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      //this.emptyTableMessage = "Loading...";

      //console.log(idToken); //to-do: remove this

      this.mobileAppInstallService.installGooglePlayApp(idToken, deviceId, packageName).subscribe({
        next: serviceResponse => {
          //to-do: testar retorno de warn, err, info
          this.dmpCommons.handleResponse(serviceResponse, undefined, undefined, false);
          this.messageService.add({ key: "permanentMessages", severity: 'info', detail: `App "${appTitle}" installed successfully` });
          //console.log("this.apps.length: " + this.apps.length);


        },
        error: error => {
          // Handle error if needed
          console.error('Error installing app:', error);
          //this.messagesVisibility = "block";
          this.messageService.add({ key: "permanentMessages", severity: 'error', detail: `Error installing app "${appTitle}"` });
        }
      });

    }).catch(error => {
        this.messageService.add({ key: "permanentMessages", severity: 'error', detail: `Error fetching current session` });
        console.error('Error fetching current session:', error);
    });
    this.modalInstallVisible = false;

  }//end installAppService


  googleAppFormatRating(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    let scoreNumber = 0;

    if (googleApp.score != undefined) {
      scoreNumber = parseFloat(googleApp.score);
    }

    text = scoreNumber.toFixed(1);

    return text;
  }//end googleAppFormatRating

  googleAppFormatDescription(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    if (googleApp.descriptionHTML != undefined) {
      text = googleApp.descriptionHTML;
    }else if (googleApp.description != undefined) {
      text = googleApp.description;
    }
    
    //text = "<b>" + "texto em bold" + "</b>";

    return text;
  }//end googleAppFormatRating

  googleAppFormatDeveloper(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    if (googleApp.developer != undefined) {
      text = googleApp.developer;
    }
    
    return text;
  }//end googleAppFormatDeveloper

  googleAppFormatTitle(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    if (googleApp.title != undefined) {
      text = googleApp.title;
    }
    
    return text;
  }//end googleAppFormatTitle

  googleAppFormatIconUrl(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    if (googleApp.icon != undefined) {
      text = googleApp.icon;
    }
    
    return text;
  }//end googleAppFormatIcon

  googleAppFormatContentRating(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    if (googleApp.details?.contentRating != undefined) {
      text = `${googleApp.details.contentRating}`;
    }
    
    return text;
  }//end googleAppFormatContentRating

  googleAppFormatDownloads(googleApp: MobileAppInstall): string {
    if (!googleApp) return '';

    let text = "";

    let downloadNumber = 0;
    let downloadText = "0";

    if (googleApp.installs != undefined) {
      downloadText = googleApp.installs.replaceAll("+", "").replaceAll(".", "");
    }
    //console.log(`downloadText: ${downloadText}`);

    downloadNumber = parseFloat(downloadText);

    if (downloadNumber >= 1000000000000) {
      downloadNumber = downloadNumber / 1000000000000;
      text = downloadNumber.toFixed(0) + " tri+ downloads";
    }else if (downloadNumber >= 1000000000) {
      downloadNumber = downloadNumber / 1000000000;
      text = downloadNumber.toFixed(0) + " bi+ downloads";
    }else if (downloadNumber >= 1000000) {
      downloadNumber = downloadNumber / 1000000;
      text = downloadNumber.toFixed(0) + " mi+ downloads";
    }else if (downloadNumber >= 1000) {
      downloadNumber = downloadNumber / 1000;
      text = downloadNumber.toFixed(0) + " k+ downloads";
    }else{
      text = downloadNumber.toFixed(0) + "+ downloads";
    }

    return text;
  }//end googleAppFormatRating

  googleAppFormatInAppPurchase(googleApp: MobileAppInstall): string | undefined {
    if (!googleApp) return '';

    let text = undefined;
    let iapText = "";
    let hasIAP:Boolean = false;

    if (googleApp.details?.offersIAP != undefined) {
      iapText = googleApp.details?.offersIAP.toString();
      hasIAP = (iapText.toLowerCase() === "true");
    }

    if (hasIAP) {
      text = "Contains in-app purchases";
    }else{
      text = "No in-app purchases";
    }

    return text;
  }

  googleAppFormatContainAds(googleApp: MobileAppInstall): string | undefined {
    if (!googleApp) return '';

    let text = undefined;
    let adsText = "";
    let hasAds:Boolean = false;

    if (googleApp.details?.containsAds != undefined) {
      adsText = googleApp.details?.containsAds.toString();
      //console.log(`adsText: ${adsText}`);
      hasAds = (adsText.toLowerCase() === "true");
    }

    if (hasAds) {
      text = "Contains Ads";
    }else{
      text = "No Ads";
    }

    return text;
  }

  //#region Properties and Change Handlers for Country and Language

  get selectedCountry(): Country | null {
    return this._selectedCountry;
  }

  set selectedCountry(value: Country | null) {
    if (this._selectedCountry !== value) {
      this._selectedCountry = value;
      this.onSelectedCountryChanged();
    }
  }

  private onSelectedCountryChanged(): void {
    console.log('selectedCountry has changed:', this._selectedCountry);

    // todo : save to local storage, and update remote value
    this.setLocalConfigValueCountry(JSON.stringify(this._selectedCountry));

    // todo : update remote value
  }

  get selectedLanguage(): Language | null {
    return this._selectedLanguage;
  }

  set selectedLanguage(value: Language | null) {
    if (this._selectedLanguage !== value) {
      this._selectedLanguage = value;
      this.onSelectedLanguageChanged();
    }
  }

  private onSelectedLanguageChanged(): void {
    console.log('selectedLanguage has changed:', this._selectedLanguage);

    // todo : save to local storage, and update remote value
    this.setLocalConfigValueLanguage(JSON.stringify(this._selectedLanguage));

    // todo : update remote value
  }

  //#endregion

  getLocalConfigValueCountry(defaultCountry: string) {
    let configKey = `urn:googleplay-search-country:user:${this.dmpCommons.userName}`;
    console.log(`getLocalConfigValueCountry(${configKey})`);

    try {
      //let rawData: string = DmpCommonsComponent.loadLocalCache(configKey) ?? '';
      let rawData: string = DmpCommonsComponent.loadLocalCache(configKey) ?? '';
      if (rawData == '') {
        console.log(`local value not found, setting default value`);
        this._selectedCountry = JSON.parse(defaultCountry) as Country;
        return;
      }

      console.log(`local rawData = ${rawData}`);

      this._selectedCountry = JSON.parse(rawData) as Country;
    } catch (error) {
      this._selectedCountry = JSON.parse(defaultCountry) as Country;
      console.log(`error handler: using default value for Country, instead of local storage value`);
    }

  }//end getLocalConfigValueCountry

  setLocalConfigValueCountry(newCountry: string) {
    let configKey = `urn:googleplay-search-country:user:${this.dmpCommons.userName}`;
    console.log(`setLocalConfigValueCountry(${configKey})`);

    try {
      DmpCommonsComponent.saveLocalCache(configKey, newCountry);
    } catch (error) {
      console.log(`Error saving local storage value for Country`);
    }

  }//end getLocalConfigValueCountry

  getLocalConfigValueLanguage(defaultLanguage: string) {
    let configKey = `urn:googleplay-search-language:user:${this.dmpCommons.userName}`;
    console.log(`getLocalConfigValueLanguage(${configKey})`);

    try {
      let rawData: string = DmpCommonsComponent.loadLocalCache(configKey) ?? '';
      if (rawData == '') {
        console.log(`local value not found, setting default value`);
        this._selectedLanguage = JSON.parse(defaultLanguage) as Language;
        return;
      }

      console.log(`local rawData = ${rawData}`);

      this._selectedLanguage = JSON.parse(rawData) as Language;
    } catch (error) {
      this._selectedLanguage = JSON.parse(defaultLanguage) as Language;
      console.log(`error handler: using default value for Language, instead of local storage value`);
    }

  }//end getLocalConfigValueLanguage

  setLocalConfigValueLanguage(newLanguage: string) {
    let configKey = `urn:googleplay-search-language:user:${this.dmpCommons.userName}`;
    console.log(`setLocalConfigValueLanguage(${configKey})`);

    try {
      //localStorage.setItem(configKey, newLanguage);
      DmpCommonsComponent.saveLocalCache(configKey, newLanguage);
    } catch (error) {
      console.log(`Error saving local storage value for Language`);
    }

  }//end getLocalConfigValueLanguage

  clearSearch() {
    console.log(`this.searchText: ${this.searchText}`);
    console.log("clearSearch");
    this.searchText = "";
    this.googleApps = [];
    this.emptyTableMessage = "";
    this.messageService.clear("permanentMessages");
  }

  handleClickDetails($event: MouseEvent, googleApp: MobileAppInstall) {
    this.googleAppImages = [];
    if (googleApp.screenshots != undefined) {
      for (let i = 0; i < googleApp.screenshots.length; i++) {
        if (googleApp.screenshots[i] != googleApp.details?.headerImage) {
          this.googleAppImages.push(googleApp.screenshots[i]);
        }else{
          console.log(`screenshot suppressed ( equals to headerImage )`);
        }
      }//end for

      //console.log(`googleApp.screenshots: [ ${googleApp.screenshots} ]`);
      //console.log(`googleApp.screenshots.length: ${googleApp.screenshots.length}`);
      //this.googleAppImages = googleApp.screenshots;
    }
    this.currentGoogleApp = googleApp;

    this.modalInstallVisible = true
  }

  loadCountries() {

    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      //this.emptyTableMessage = "Loading...";
      
      console.log(`Listing Countries`);

      this.googlePlayCountryService.getGooglePlayCountries().subscribe({
        next: googlePlayCountryAppResponse => {
          //to-do: testar retorno de warn, err, info
          this.dmpCommons.handleResponse(googlePlayCountryAppResponse, undefined, undefined, false);
          this.countries = googlePlayCountryAppResponse.data;
          if (this.countries.length == 0) {
            this.emptyTableMessage = "No data found";
          }
          //console.log("this.apps.length: " + this.apps.length);
        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching apps:', error);
        }
      });

    })
    .catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession():', error);
    });
  }//end loadCountries

  loadLanguages() {

    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      //this.emptyTableMessage = "Loading...";
      
      console.log(`Listing Languages`);

      this.googlePlayLanguageService.getGooglePlayLanguages().subscribe({
        next: googlePlayLanguageAppResponse => {
          //to-do: testar retorno de warn, err, info
          this.dmpCommons.handleResponse(googlePlayLanguageAppResponse, undefined, undefined, false);
          this.languages = googlePlayLanguageAppResponse.data;
          if (this.languages.length == 0) {
            this.emptyTableMessage = "No data found";
          }
          //console.log("this.apps.length: " + this.apps.length);
        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching apps:', error);
        }
      });

    })
    .catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession():', error);
    });
  }//end loadLanguages

  //#region cognito
  forceSignOut () {
    this.authenticator.signOut();
    this.router.navigate(['/sign-in']);
  }
  
  //#endregion

  //#region Table Methods
  onRowSelect(event: any) { 
    let googleApp = event.data
    this.selectedGoogleAppId = googleApp.appId;
    console.log("onRowSelect event: googleApp.appId: " + googleApp.appId);
  } 

  onRowUnselect(event: any) { 
    this.selectedGoogleAppId = undefined;
    console.log("onRowUnselect event");
  }

  formatCurrency(value: number) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }


  clear(table: Table) {
      table.clear();
      this.filter.nativeElement.value = '';
  }

  handleClickSearchGooglePlay(event: Event, apiVersion: string): void {
    this.googleApps = [];
    let query = (event.target as HTMLInputElement).value;
    this.searchGooglePlay(query, apiVersion);
  }

  private searchGooglePlay(queryText: string, apiVersion: string) {
    let nHits = (this.googleAppsSearchLimit + 1).toString();

    if (this._selectedCountry == undefined) {
      this.selectedCountry = JSON.parse(this.defaultCountry) as Country; //Triggers save to local storage ( and remote )
    }

    if (this._selectedLanguage == undefined) {
      this.selectedLanguage = JSON.parse(this.defaultLanguage) as Language; //Triggers save to local storage ( and remote )
    }

    let countryCode = this.selectedCountry?.code ?? "";
    let languageCode = this.selectedLanguage?.code ?? "";

    //#region service data
    this.searchGooglePlayService(
      queryText,
      nHits,
      languageCode,
      countryCode,
      apiVersion
    );
    //#endregion
  }//end searchGooglePlay

  getConfigValueCountry(defaultValue: any): any {
    ////////////
    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      //this.emptyTableMessage = "Loading...";
      
      let configKey = `urn:googleplay-search-country:user:${this.dmpCommons.userName}`;
      
      console.log(`service:getConfigValueCountry(${configKey})`);

      this.configService.getConfigValue(idToken, configKey, defaultValue).subscribe({
        next: configServiceResponse => {
          //to-do: testar retorno de warn, err, info
          this.dmpCommons.handleResponse(configServiceResponse, undefined, undefined, false);
          let newValue = configServiceResponse.data.configValue;
          
          if (newValue == undefined) {
            newValue = defaultValue;
          }

          console.log(`set targetObject = ${newValue}`);

          this._selectedCountry = JSON.parse(newValue) as Country;
          //console.log("this.googleApps.length: " + this.googleApps.length);
          
        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching google apps:', error);
          this._selectedCountry = JSON.parse(defaultValue) as Country;
        }
      });

    })
    .catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession():', error);
        this._selectedCountry = JSON.parse(defaultValue) as Country;
    });
    ////////////
  }

  getConfigValueLanguage(defaultValue: any): any {
    ////////////
    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      //this.emptyTableMessage = "Loading...";
      
      let configKey = `urn:googleplay-search-language:user:${this.dmpCommons.userName}`;
      
      console.log(`service:getConfigValueLanguage(${configKey})`);

      this.configService.getConfigValue(idToken, configKey, defaultValue).subscribe({
        next: configServiceResponse => {
          //to-do: testar retorno de warn, err, info
          this.dmpCommons.handleResponse(configServiceResponse, undefined, undefined, false);

          let newValue = configServiceResponse.data.configValue;
          
          if (newValue == undefined) {
            newValue = defaultValue;
          }

          console.log(`set targetObject = ${newValue}`);

          this._selectedLanguage = JSON.parse(newValue) as Language;
          //console.log("this.googleApps.length: " + this.googleApps.length);
          
        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching google apps:', error);
          this._selectedLanguage = JSON.parse(defaultValue) as Language;
        }
      });

    })
    .catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession():', error);
        this._selectedLanguage = JSON.parse(defaultValue) as Language;
    });
    ////////////
  }

  searchGooglePlayService(query: string, n_hits: string, lang: string, country: string, apiVersion: string) {

    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      this.emptyTableMessage = "Searching...";
      this.messageService.clear("permanentMessages");
      
      console.log(`Search apps from google play with query ${query}, lang ${lang}, country ${country}, n_hits ${n_hits}`);

      this.mobileAppInstallService.getGooglePlayApps(idToken, query, n_hits, lang, country, apiVersion).subscribe({
        next: mobileAppGooglePlayResponse => {
          //to-do: testar retorno de warn, err, info
          this.dmpCommons.handleResponse(mobileAppGooglePlayResponse, undefined, undefined, false);
          this.googleApps = mobileAppGooglePlayResponse.data;
          if (this.googleApps.length == 0) {
            this.emptyTableMessage = "No data found";
          }else if (this.googleApps.length > this.googleAppsSearchLimit) {
            let summaryMessage = `The search returned too many items !`;
            let detailsMessage = `Please refine your search`;
            this.messageService.add({ key: "permanentMessages", severity: "warn", summary: summaryMessage, detail: detailsMessage });
          }
          //console.log("this.googleApps.length: " + this.googleApps.length);
          
        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching google apps:', error);
        }
      });

    })
    .catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession():', error);
    });
  }//end searchGooglePlay

  //#endregion

}//end class

//export class DeviceModule {}


