import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '../api/device';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeviceService {

    constructor(private http: HttpClient) { }

    getDevices(idToken: string, enterpriseId: string): Observable<any> {

        const httpOptions = {
          headers: { 
            'Content-Type': 'application/json',
            'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': idToken,
            'x-api-key': environment.xApiKey
            
         },
          params: {'enterprise_id': enterpriseId}
        };

        let basePath = environment.backendBaseURL;
        let path = "/v1/devices";

        console.log(`${basePath}${path}`, httpOptions);
        console.log(idToken);

        let response = this.http.get(`${basePath}${path}`, httpOptions);

        return response;
    }

    createEnrollmentLink(idToken: string, enterpriseId: string, policyId: string, extraParams: string): Observable<any> {
      //New Device Registration Service (Returns enrolmment link do add/register a new device)

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };

      //to-do: here the device policy is generic, but the device can receive a specific policy, when some specific action is needed, like uninstall an app. 
      //So, if the needs some specific policy ( after the device is present, after the enrollment, we can create a new policy just like the initial generic, and then change patch the device with this new specific policy )  https://developers.google.com/android/management/reference/rest/v1/enterprises.devices/patch

      let customPolicyForDevice = policyId;

      const body = {
        enterprise_id: enterpriseId,
        policy_id: customPolicyForDevice, //can be web or mobile for example
        extraParams: extraParams
      };
  
      let basePath = environment.backendBaseURL;
      let path = "/v1/enrollments";

      console.log(`about to create registration link with enterpriseId: ${enterpriseId}, policyId: ${customPolicyForDevice}, idToken: ${idToken}`)

      let response = this.http.post(`${basePath}${path}`, body, httpOptions);

      console.log(`response: ${response}`);
  
      return response;
    }

    lockDevice(idToken: string, enterpriseId: string, deviceId: string, newPassword: string | undefined): Observable<any> {
      //New Device Registration Service (Returns enrolmment link do add/register a new device)

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };

      //to-do: here the device policy is generic, but the device can receive a specific policy, when some specific action is needed, like uninstall an app. 
      //So, if the needs some specific policy ( after the device is present, after the enrollment, we can create a new policy just like the initial generic, and then change patch the device with this new specific policy )  https://developers.google.com/android/management/reference/rest/v1/enterprises.devices/patch

      const body = {
        enterprise_id: enterpriseId,
        device_id: deviceId,
        new_password: newPassword
      };
  
      let basePath = environment.backendBaseURL;
      let path = "/v1/locked-devices";

      console.log(`about to lock device with request body: ${body}, enterpriseId: ${enterpriseId}, deviceId: ${deviceId}, newPassword: ${newPassword}`)

      let response = this.http.post(`${basePath}${path}`, body, httpOptions);

      console.log(`response: ${response}`);
  
      return response;
    }

    deleteDevice(idToken: string, enterpriseId: string, deviceId: string): Observable<any> {
      //New Device Registration Service (Returns enrolmment link do add/register a new device)

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };

      const body = {
        enterprise_id: enterpriseId,
        device_id: deviceId,
        perform_wipe_external_storage: true
      };

  
      let basePath = environment.backendBaseURL;
      let path = "/v1/wiped-devices";

      console.log(`about to delete device with enterpriseId: ${enterpriseId}, deviceId: ${deviceId}`)

      let response = this.http.post(`${basePath}${path}`, body, httpOptions);

      return response;
    }

    getDevicesMock(idToken: string, enterpriseId: string): Observable<any> {
      return this.http.get<any>('../../assets/mock/data/devices.json');
    }

}
