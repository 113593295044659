<app-dmp-commons></app-dmp-commons>

<!--
<p-toolbar styleClass="mb-4">
    <p-fieldset legend="Config: V 2.0.0" [toggleable]="true" [collapsed]="true">
        <input type="password" [(ngModel)]="clientKey" size="4" pInputText pTooltip="client-key" placeholder="key" [disabled]="isClientKeyDisabled">
        <input type="password" [(ngModel)]="clientSecret" size="4" pInputText pTooltip="client-secret" placeholder="key" [disabled]="isClientSecretDisabled">
        <button pButton pRipple label="Accept" icon="pi ps-check" class="p-button-success mr-2" (click)="acceptSecretKey(this.clientKey, this.clientSecret)"  [disabled]="isButtonAcceptKeyDisabled"></button>
    </p-fieldset>
</p-toolbar>
-->

<p-toolbar styleClass="mb-4">
    <p-fieldset legend="v2.0.0 : Commands" [toggleable]="true">
    
        <p>
            <button pButton pRipple style="font-family: courier new;" label="&nbsp;&nbsp;&nbsp;Ping&nbsp;&nbsp;&nbsp;" icon="pi" class="p-button-success mr-2" (click)="raiseCommandAws('ping')"></button><br><br>

            <button pButton pRipple style="font-family: courier new;" label="&nbsp;&nbsp;&nbsp;Lock&nbsp;&nbsp;&nbsp;" icon="pi" class="p-button-success mr-2" (click)="raiseCommandAws('lock')"></button>

        </p>
        <p>
            <button pButton pRipple style="font-family: courier new;" label="&nbsp;&nbsp;&nbsp;Wipe&nbsp;&nbsp;&nbsp;" icon="pi" class="p-button-success mr-2" (click)="raiseCommandAws('wipe')"></button><br><br>

            <button pButton pRipple style="font-family: courier new;" label="&nbsp;&nbsp;Reboot&nbsp;&nbsp;" icon="pi" class="p-button-success mr-2" (click)="raiseCommandAws('reboot')"></button>
        </p>
        <p>
            <button pButton pRipple style="font-family: courier new;" label="&nbsp;Shutdown&nbsp;" icon="pi" class="p-button-success mr-2" (click)="raiseCommandAws('shutdown')"></button><br><br>
        </p>
        <p>
            <button pButton pRipple style="font-family: courier new;" label="Force&nbsp;Wipe" icon="pi" class="p-button-success mr-2" (click)="confirmRaiseCommandAws('forcewipe')"></button>
            <input [(ngModel)]="pwdActionForceWipe" type="password" size="4" pInputText pTooltip="Passphrase to wipe" placeholder="Password to Force Wipe" autocomplete="off">
        </p>

    

    </p-fieldset>

    <p></p>
</p-toolbar>

<div class="card flex justify-content-center gap-2">
    
    <p-confirmDialog [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

</div>

<p-fieldset legend="Action History" [toggleable]="true">
    <p-messages></p-messages>
</p-fieldset>
