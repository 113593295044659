import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MobileAppInstall } from '../api/mobile-app-install';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';

@Injectable()
export class MobileAppInstallService {

    constructor(private http: HttpClient) { }

    installGooglePlayApp(
      idToken: string, 
      deviceId: string,
      appPagackeName: string
    ): Observable<any> {
      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };

      const body = {
        device_id: deviceId,
        app_name: appPagackeName
      };
  
      let basePath = environment.backendBaseURL;
      let path = "/v1/apps";

      console.log(`about to install app: ${appPagackeName}, deviceId: ${deviceId}`)

      let response = this.http.put(`${basePath}${path}`, body, httpOptions);

      console.log(`response: ${response}`);
  
      return response;
    }//end installGooglePlayApp

    //Search for apps in Google Play Store
    getGooglePlayApps(
      idToken: string, 
      query: string,
      nHits: string,
      lang: string,
      country: string,
      apiVersion: string
    ): Observable<any> {

      if (environment.mockServiceMobileAppInstall) {
        return this.http.get<any>('../../assets/mock/data/mobile-google-play-apps.json');
      }

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
          
        },
        params: {
          'query': query,
          'n_hits': nHits,
          'lang': lang,
          'country': country
        }
      };

      let basePath = environment.backendBaseURL;
      let path = `/${apiVersion}/google-play-apps`;

      console.log("about to get apps with httpOptions: ", httpOptions);

      let response = this.http.get(`${basePath}${path}`, httpOptions);

      return response;
    }//end getGooglePlayApps

}//end MobileAppInstallService