import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MessageBrokerService {
  //private messageSource = new Subject<string>();
  //message$ = this.messageSource.asObservable();

  private eventSource = new Subject<EventSchema>();
  event$ = this.eventSource.asObservable();

  sendEventMessage(messageContent: EventSchema) {
    console.log('event sent: messageContent.key: ', messageContent.key);
    console.log('event sent: messageContent.value: ', messageContent.value);
    this.eventSource.next(messageContent);
  }

}//end of class

//It MUST remain on the end of the file
export class EventSchema {
  key: string;
  value: any;
}