import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enterprise } from '../api/enterprise';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';

@Injectable()
export class EnterpriseService {

  constructor(private http: HttpClient) { }

  getEnterprises(idToken: string, projectId: string): Observable<any> {

    const httpOptions = {
      headers: { 
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': idToken,
        'x-api-key': environment.xApiKey
        
      },
      params: {'project_id': projectId}
    };
    
    console.log("about to get enterprises with httpOptions: ", httpOptions);

    let basePath = environment.backendBaseURL;
    let path = "/v1/enterprises";

    console.log(`${basePath}${path}`);

    let response = this.http.get(`${basePath}${path}`, httpOptions);


    return response;
  }

  createSignupLink(idToken: string, projectId: string, source: string): Observable<any> {

    const httpOptions = {
      headers: { 
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': idToken,
        'x-api-key': environment.xApiKey
      }
    };

    console.log(`createSignupLink request headers: ${httpOptions}`);

    const body = {
      project_id: projectId,
      src: source //can be web or mobile for example
    };

    let basePath = environment.backendBaseURL;
    let path = "/v1/enterprise-signups";

    let response = this.http.post(`${basePath}${path}`, body, httpOptions);

    return response;
  }

  createEnterprise(callbackId: string, idToken: string, projectId: string, enterpriseToken: string | undefined, signupUrlName: string | undefined): Observable<any> {

    const httpOptions = {
      headers: { 
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': idToken,
        'x-api-key': environment.xApiKey
      }
    };

    const body = {
      callback_id: callbackId,
      project_id: projectId,
      signup_url_name: signupUrlName,
      enterprise_token: enterpriseToken
    };

    let basePath = environment.backendBaseURL;
    let path = "/v1/enterprises";

    let response = this.http.post(`${basePath}${path}`, body, httpOptions);

    return response;
  }

  deleteEnterprise(idToken: string, enterpriseId: string): Observable<any> {

    const httpOptions = {
      headers: { 
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': idToken,
        'x-api-key': environment.xApiKey
      }
    };

    let basePath = environment.backendBaseURL;
    let path = `/v1/enterprises/${enterpriseId}`;

    let response = this.http.delete(`${basePath}${path}`, httpOptions);

    return response;
  }

  getEnterprisesMock(idToken: string, projectId: string): Observable<any> {
    return this.http.get<any>('../../assets/mock/data/enterprises.json');
  }

}
