
<amplify-authenticator [hideSignUp]="true" variation="modal">

    <ng-template
      amplifySlot="authenticated"
      let-user="user"
      let-signOut="signOut"
    >
      <p></p>

      <p-messages severity="info">
        <ng-template pTemplate>
            <img src="../../../assets/layout/images/lock.jpeg" width="32" />
            <div class="ml-2">&nbsp;<b>User</b> [{{ authenticator.user.username }}] <b> authenticated !</b></div>
            <!-- https://ui.docs.amplify.aws/angular/connected-components/authenticator/advanced -->
        </ng-template>


      </p-messages>

      <p-messages severity="info">
        <ng-template pTemplate>
          <a routerLink="/home">Home</a>
        </ng-template>
      </p-messages>
      
      <button (click)="signOut()">Sign Out</button>
    </ng-template>

  </amplify-authenticator>

