
export const environment = {
  buildForRelease: false,
  backendBaseURL: 'https://apidmp.webteclife.com.br',
  suppressConsoleLog: false, //Should be TRUE in real production
  environmentName: 'Development',
  xApiKey: '5TE9W3dKk424m36okFAw78lozff9CBWj4Ws01Dov',

  mockServiceGooglePlayCountries: true,
  mockServiceGooglePlayLanguages: true,
  mockServiceConfigGooglePlayCountry: true,
  mockServiceConfigGooglePlayLanguage: true,

  mockServiceMobileAppInstall: false,
  };