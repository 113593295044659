import { Injectable } from '@angular/core';
import { HttpClient, HttpParams , HttpErrorResponse, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemoteActionService {
  constructor(private http: HttpClient) {}

  // Define your API endpoint here
  private apiUrlAws = 'https://api.webteclife.com.br';
  private apiv2PathAws = '/v2/remote-device-actions';
    
  getActionState(requestId: string, idToken: string): Observable<any> {
    console.info("getActionState("+requestId+")");
    

     const httpOptions = {
      headers: { 
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': idToken
        
     },
      params: {'id': requestId}
    };
    
    return this.http.get(`${this.apiUrlAws}${this.apiv2PathAws}`, httpOptions);
  }

  // Create methods for HTTP requests
  remoteActionUsingAWS(token: string, actionName: string, requestId: string): Observable<any> {
    return this.remoteActionUsingAWSUsingCognito(token, actionName, requestId);
  }

  remoteActionUsingAWSUsingCognito(token: string, actionName: string, requestId: string): Observable<any> {
    console.info("RemoteDeviceService.remoteActionUsingAWSUsingCognito(" + actionName + ")");

    const headers = new HttpHeaders({
      'Authorization': token.toString()
    });
    
    const body = { id: requestId, action: actionName, state: 'requested' };
    
    return this.http.put(`${this.apiUrlAws}${this.apiv2PathAws}`, body, { headers });
  }


}
