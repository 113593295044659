import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class GooglePlayLanguageService {

    constructor(private http: HttpClient) { }

    getGooglePlayLanguages(): Observable<any> {
      //ISO-3166 Country Codes and ISO-639 Language Codes, like in https://github.com/pycountry/pycountry

      if (environment.mockServiceGooglePlayLanguages) {
        return this.http.get<any>('../../assets/mock/data/languages.json');
      }

      let response = new Observable<any>;
      return response;
    }

}
