import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';

@Injectable()
export class ConfigService {

    constructor(private http: HttpClient) { }

    delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getConfigValue(idToken: string, configKey: string, defaultValue: string) : Observable<any> {
        console.log(`timestamp getConfigValue start: ${new Date().getTime()}`);
        return from(this.delay(2000)).pipe(
            concatMap(() => this.getDBConfigValue(idToken, configKey, defaultValue))
        );
    }//end getConfigValue

    getDBConfigValue(idToken: string, configKey: string, defaultValue: string) {
        
        if(
            configKey.startsWith("urn:googleplay-search-country:user:")
            && environment.mockServiceConfigGooglePlayCountry) {
                return this.http.get<any>('../../assets/mock/data/config-value-country.json');
        } else if(
            configKey.startsWith("urn:googleplay-search-language:user:")
            && environment.mockServiceConfigGooglePlayLanguage) {
                return this.http.get<any>('../../assets/mock/data/config-value-language.json');
        }

        const httpOptions = {
        headers: { 
            'Content-Type': 'application/json',
            'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': idToken,
            'x-api-key': environment.xApiKey
            
        },
        params: {'config_key': configKey}
        };
            
        console.log("about to get config with httpOptions: ", httpOptions);
    
        let basePath = environment.backendBaseURL;
        let path = "/v1/configs";
    
        console.log(`${basePath}${path}`);
    
        let response = this.http.get(`${basePath}${path}`, httpOptions);
        console.log(`timestamp getConfigValue end: ${new Date().getTime()}`);
        
        return response;
    }//end getDBConfigValue

}//end ConfigService
