import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './dmp/components/home/home.component';
import { SignInComponent } from './dmp/components/sign-in/sign-in.component';
import { SignUpComponent } from './dmp/components/sign-up/sign-up.component';
import { EnterpriseComponent } from './dmp/components/enterprise/enterprise.component';
import { DeviceComponent } from './dmp/components/device/device.component';
import { MobileAppComponent } from './dmp/components/mobile-app/mobile-app.component';
import { TesteComponent } from './dmp/components/teste/teste.component';
import { MobileAppInstallComponent } from './dmp/components/mobile-app-install/mobile-app-install.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { CommonModule } from '@angular/common'; // Import CommonModule

const routes: Routes = [
  //{path: 'home', component: EnterpriseComponent},


  {
    path: 'home', component: AppLayoutComponent,
    children: [
        { path: 'dashboard', loadChildren: () => import('./dmp/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
        { path: 'enterprises', component:EnterpriseComponent },
        { path: 'devices/:enterpriseId', component:DeviceComponent },
        { path: 'apps/:enterpriseId/:emmDeviceId/:policyId/:deviceId', component: MobileAppComponent},
        { path: 'mobile-app-install/:enterpriseId/:deviceId/:policyId', component: MobileAppInstallComponent},
        
        /*
        { path: 'uikit', loadChildren: () => import\\('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
        { path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
        { path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
        { path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
        { path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) }
         */
        {path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
},

  {path: 'sign-in', component: SignInComponent},
  {path: 'sign-up', component: SignUpComponent},
  {path: 'enterprises', component: EnterpriseComponent},
  {path: 'admin', component: EnterpriseComponent},
  {path: 'enterprises/:callbackArguments', component: EnterpriseComponent},
  {path: 'devices/:enterpriseId', component: DeviceComponent},
  {path: 'apps/:enterpriseId/:emmDeviceId/:policyId/:deviceId', component: MobileAppComponent},
  {path: 'mobile-app-install/:enterpriseId/:deviceId/:policyId', component: MobileAppInstallComponent},
  {path: 'teste/:enterpriseId/:deviceId/:policyId', component: TesteComponent},
  {path: 'legacy', component: HomeComponent},

  {path: '**',   redirectTo: 'home', pathMatch: 'full' },
  //{path: '',     redirectTo: 'sign-in', pathMatch: 'full' },
  ];


@NgModule({
  imports: [
    RouterModule.forRoot(routes), 
    CommonModule
  ], 
  exports: [RouterModule]
})

export class AppRoutingModule { }
