import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { User } from '../../models/user';
import { CognitoService } from 'src/app/dmp/services/cognito.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import awsExports from '../../../../aws-exports';




@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})




export class SignInComponent implements OnInit{
  user : User | undefined;
  alertMessage : string = '';  
  showAlert : boolean = false;

  isForgotPassword : boolean = false;
  newPassword : string = '';

  constructor( 
    private router:Router, 
    private cognitoService: CognitoService,
    public authenticator: AuthenticatorService  ) { 
    Amplify.configure(awsExports);

    this.authenticator = authenticator;
    //First route to home if already authenticated

    authenticator.subscribe((data: any) => {
      console.log("authenticator.subscribe: route to home if autenticated");
      let session = data;
      if (data.authStatus === "authenticated") {
        console.log("authenticator.subscribe: autenticated is true, navigating to home");
        this.router.navigate(['/home']);
      };
    })
    
  }//end constructor

  ngOnInit(): void {
    this.user = {} as User;
    //console.log("ngOnInit(): navigating to home");
    //this.router.navigate(['/home']);
  }//end ngOnInit

  async currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      return idToken;
    } catch (err) {
      console.log(err);
    }
    
    return "";
  }

/*
  async signInWithCognito() {
    if (this.user && this.user.username && this.user.password) {
      try {
        await this.cognitoService.signIn(this.user);
        const isAuthenticated = await this.cognitoService.isAuthenticated();
        if (isAuthenticated) {
          this.router.navigate(['/home']);
        }
      } catch (error) {
        // Handle authentication error
      }
 
    }
  }

  private displayAlert(message:string) {
    this.alertMessage = message;
    this.showAlert = true;
  }
  */

}
