import { NgModule, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {RouterModule} from '@angular/router';
import { DmpCommonsComponent } from '../dmp/components/shared/dmp-commons/dmp-commons.component';
import { Messages, MessagesModule } from 'primeng/messages';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppMenuitemComponent } from '../layout/app.menuitem.component'; // Import the directive
import { CommonModule } from '@angular/common'; // Import CommonModule
import { Message, MessageService } from 'primeng/api';''
import { ToolbarModule } from 'primeng/toolbar';
import { EventSchema, MessageBrokerService } from './service/message.broker.service'; // Adjust the path as needed
//import { MessageBrokerService } from './service/message.broker.service'; // Adjust the path as needed
import { filter } from 'rxjs';
import { Enterprise } from '../dmp/api/enterprise';
import { Device } from '../dmp/api/device';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['../dmp/components/shared/dmp-commons/dmp-commons.component.css'],
    providers: [MessageService, DmpCommonsComponent]
})

export class AppMenuComponent implements OnInit {
    messages: string[] = [];
    eventsReceived: EventSchema[] = [];

    model: any[] = [];

    enterpriseFilterMessages: Message[] = []; 
    isEnterpriseFilterVisible: boolean = false;
    selectedEnterpriseName?: string = undefined;

    deviceFilterMessages: Message[] = []; 
    isDeviceFilterVisible: boolean = false;
    selectedDeviceName?: string = undefined;

    constructor(
                private messageBrokerService: MessageBrokerService,
                public layoutService: LayoutService,
                public dmpCommons: DmpCommonsComponent,
                private messageService: MessageService
    ) { }


    ngOnInit() {
        console.log(`this.showMenu() from ngOnInit()`);
        this.showMenu();

        this.eventsConsumer();

    }//end ngOnInit

    private eventsConsumer() {
        this.messageBrokerService.event$.subscribe(rawMessage => {

            console.log(`event received: ${rawMessage}`);
            let messageObject = rawMessage as EventSchema;
            console.log(`messageObject.key: ${messageObject.key}`);

            this.dmpCommons.hasRoleDeviceOwner().then((isDeviceOwner: boolean) => {
              console.log("isDeviceOwner from promise: " + isDeviceOwner);
              if (isDeviceOwner)  {
                switch (messageObject.key) {
                    case 'device-selected':
                        let device: Device = messageObject.value as Device;

                        this.selectedDeviceName = `${device.friendlyName}`;

                        console.log(`deviceName: ${device.name}`);
                        console.log(`deviceDisplayName: ${device.friendlyName}`);

                        this.handleEventDevice(messageObject);
                        break;
                    case 'device-unselected':
                        this.selectedDeviceName = undefined;
                        this.handleEventDeviceUnselect(messageObject);
                        break;
                }//end switch
              }//end if (isDeviceOwner)
            }).catch((error: any) => {
              console.log("isDeviceOwnerPromise: error: " + error);
            });
        
            this.dmpCommons.hasRolePlatformAdmin().then((isPlatformAdmin: boolean) => {
              console.log("isPlatformAdmin from promise: " + isPlatformAdmin);
              if (isPlatformAdmin) {
                switch (messageObject.key) {
                    case 'enterprise-selected':
                        let enterprise: Enterprise = messageObject.value as Enterprise;

                        this.selectedEnterpriseName = `${enterprise.name}`.substring('enterprises/'.length);

                        console.log(`enterpriseName: ${enterprise.name}`);
                        console.log(`enterpriseDisplayName: ${enterprise.enterpriseDisplayName}`);

                        this.handleEventEnterprise(messageObject);
                        break;
                    case 'enterprise-unselected':
                        this.selectedEnterpriseName = undefined;
                        this.handleEventEnterpriseUnselect(messageObject);
                        break;

                    case 'device-selected':
                        let device: Device = messageObject.value as Device;

                        this.selectedDeviceName = `${device.friendlyName}`;

                        console.log(`deviceName: ${device.name}`);
                        console.log(`deviceDisplayName: ${device.friendlyName}`);

                        this.handleEventDevice(messageObject);
                        break;
                    case 'device-unselected':
                        this.selectedDeviceName = undefined;
                        this.handleEventDeviceUnselect(messageObject);
                        break;
                }//end switch
              }//end if (isPlatformAdmin)
            }).catch((error: any) => {
              console.log("isPlatformAdminPromise: error: " + error);
            });

            this.eventsReceived.push(messageObject);

            console.log(`this.showMenu() from eventsConsumer()`);
            this.showMenu();
        });
    }

    showMenu(): void {
        //console.log("showMenu");

        let isDeviceOwnerPromise = this.dmpCommons.hasRoleDeviceOwner();
        isDeviceOwnerPromise.then((isDeviceOwner: boolean) => {
          //console.log("isDeviceOwner from promise: " + isDeviceOwner);
          if (isDeviceOwner)  {
            this.model = this.deviceOwnerMenu();
          }//end if (isDeviceOwner)
        }).catch((error: any) => {
          console.log("isDeviceOwnerPromise: error: " + error);
        });
    
        console.log("this.dmpCommons.hasRolePlatformAdmin().then((isPlatformAdmin: boolean) => { ...");
        this.dmpCommons.hasRolePlatformAdmin().then((isPlatformAdmin: boolean) => {
          console.log("isPlatformAdmin from promise: " + isPlatformAdmin);
          if (isPlatformAdmin) {
            this.model = this.platformAdminMenu();
          }//end if (isPlatformAdmin)
        }).catch((error: any) => {
          console.log("isPlatformAdminPromise: error: " + error);
        });

    }//end showMenu

    //#region Enterprise Events
    private handleEventEnterprise(messageContent: EventSchema) {
        console.log(`handleEventEnterprise: messageContent.key: ${messageContent.key}`);
        //if (messageContent.key != 'enterprise-selected') return; //REVER, RETIRAR

        this.showEnterpriseFilter(messageContent);
    }

    private handleEventEnterpriseUnselect(messageContent: EventSchema) {
        console.log(`handleEventEnterprise: messageContent.key: ${messageContent.key}`);
        //if (messageContent.key != 'enterprise-unselected') return; //REVER, RETIRAR

        this.clearEnterpriseFilter(messageContent);
    }

    private showEnterpriseFilter(messageContent: EventSchema) {
        let enterpriseSelected: Enterprise = messageContent.value as Enterprise;
        let preffixToIgnore = 'enterprises/';
        let enterpriseName = enterpriseSelected.name?.substring(preffixToIgnore.length);
        let enterpriseDisplayName = enterpriseSelected.enterpriseDisplayName?enterpriseSelected.enterpriseDisplayName:"";

        console.log(`selected:enterpriseName: ${enterpriseName}`);
        console.log(`selected:enterpriseNameClean: ${enterpriseDisplayName}`);

        /*
        this.filterMessages = [
            {
                severity: 'info',
                summary: 'Enterprise',
                detail: `${enterpriseDisplayName} (${enterpriseName})`,
            },
        ];*/

        this.enterpriseFilterMessages = [
            {
                severity: 'info',
                detail: `${enterpriseDisplayName} (${enterpriseName})`,
            },
        ];

        //#region button
        this.isEnterpriseFilterVisible = true;
        //this.selectedEnterpriseName = `Enterprise: ${enterpriseDisplayName} (${enterpriseName})`;
        this.selectedEnterpriseName = `${enterpriseName}`;
        //#endregion

        this.clearDeviceFilter(messageContent);
    }

    private clearEnterpriseFilter(messageContent: EventSchema) {

        this.enterpriseFilterMessages = [
        ];

        //#region button
        //this.isEnterpriseFilterVisible = true;
        //this.selectedEnterpriseName = `Enterprise: ${enterpriseNameClean}`;
        //#endregion
    }

    onEnterpriseFilterMessageClose(event: any) {
        console.log('Message closed:', event);
        this.selectedEnterpriseName = undefined;
        this.enterpriseFilterMessages = [];
        this.showMenu();
    }//end method

    onButtonEnterpriseClose(event: any) {
        console.log('Button Message closed:', event);
        this.isEnterpriseFilterVisible = false;
        // Your custom logic here, e.g., reset the message array or perform some cleanup
    }//end method

    //#endregion Enterprise

    //#region Device Events
    private handleEventDevice(messageContent: EventSchema) {
        console.log(`handleEventDevice: messageContent.key: ${messageContent.key}`);
        //if (messageContent.key != 'device-selected') return; //REVER, RETIRAR

        this.showDeviceFilter(messageContent);
    }

    private handleEventDeviceUnselect(messageContent: EventSchema) {
        console.log(`handleEventEnterprise: messageContent.key: ${messageContent.key}`);
        //if (messageContent.key != 'enterprise-unselected') return; //REVER, RETIRAR

        this.clearDeviceFilter(messageContent);
    }

    private showDeviceFilter(messageContent: EventSchema) {
        let deviceSelected: Device = messageContent.value as Device;
        let preffixToIgnore = 'devices/';
        let preffixLenToIgnore: number = deviceSelected.name?.lastIndexOf(preffixToIgnore)??0;
        let deviceName = deviceSelected.name?.substring(preffixLenToIgnore + preffixToIgnore.length);
        let deviceDisplayName = deviceSelected.friendlyName;

        console.log(`selected:deviceName: ${deviceName}`);
        console.log(`selected:deviceNameClean: ${deviceDisplayName}`);

        this.deviceFilterMessages = [
            {
                severity: 'info',
                detail: `${deviceDisplayName}`,
            },
        ];

        //#region button
        this.isDeviceFilterVisible = true;
        this.selectedDeviceName = `Device: ${deviceDisplayName} (${deviceName})`;
        //#endregion
    }

    private clearDeviceFilter(messageContent: EventSchema) {

        this.deviceFilterMessages = [
        ];

    }

    onDeviceFilterMessageClose(event: any) {
        console.log('Message closed:', event);
        this.selectedDeviceName = undefined;
        this.deviceFilterMessages = [];
        this.showMenu();
    }//end method

    onButtonDeviceClose(event: any) {
        console.log('Button Message closed:', event);
        this.isDeviceFilterVisible = false;
        // Your custom logic here, e.g., reset the message array or perform some cleanup
    }//end method
    //#endregion Device


    defaultMenu(): any[] {
        let model:any[] = 
        [
            {
                label: 'Home',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
                ]
            },
            {
                label: 'UI Components',
                items: [
                    { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
                    { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
                    { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
                    { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
                    { label: 'Button', icon: 'pi pi-fw pi-box', routerLink: ['/uikit/button'] },
                    { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
                    { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
                    { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
                    { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
                    { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
                    { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
                    { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
                    { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
                    { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
                    { label: 'Misc', icon: 'pi pi-fw pi-circle', routerLink: ['/uikit/misc'] }
                ]
            },
            {
                label: 'Prime Blocks',
                items: [
                    { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks'], badge: 'NEW' },
                    { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank' },
                ]
            },
            {
                label: 'Utilities',
                items: [
                    { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/utilities/icons'] },
                    { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
                ]
            },
            {
                label: 'Pages',
                icon: 'pi pi-fw pi-briefcase',
                items: [
                    {
                        label: 'Landing',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['/landing']
                    },
                    {
                        label: 'Auth',
                        icon: 'pi pi-fw pi-user',
                        items: [
                            {
                                label: 'Login',
                                icon: 'pi pi-fw pi-sign-in',
                                routerLink: ['/auth/login']
                            },
                            {
                                label: 'Error',
                                icon: 'pi pi-fw pi-times-circle',
                                routerLink: ['/auth/error']
                            },
                            {
                                label: 'Access Denied',
                                icon: 'pi pi-fw pi-lock',
                                routerLink: ['/auth/access']
                            }
                        ]
                    },
                    {
                        label: 'Crud',
                        icon: 'pi pi-fw pi-pencil',
                        routerLink: ['/pages/crud']
                    },
                    {
                        label: 'Timeline',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/pages/timeline']
                    },
                    {
                        label: 'Not Found',
                        icon: 'pi pi-fw pi-exclamation-circle',
                        routerLink: ['/notfound']
                    },
                    {
                        label: 'Empty',
                        icon: 'pi pi-fw pi-circle-off',
                        routerLink: ['/pages/empty']
                    },
                ]
            },
            {
                label: 'Hierarchy',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                                    { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                                    { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                                    { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Get Started',
                items: [
                    {
                        label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
                    },
                    {
                        label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sakai-ng'], target: '_blank'
                    }
                ]
            }
        ];
        return model;
    }//end defaultMenu

    platformAdminMenu(): any[] {
        console.log('platformAdminMenu()');
        let model:any[] = 
        [
            {
                label: 'Platform Admin',
                items: [
                    { label: 'Enterprises', icon: 'pi pi-fw pi-list', routerLink: ['/home/enterprises'] }
                ]
            },
        ];

        if (this.selectedEnterpriseName != undefined) {
            let menuItens = model[0].items;

            menuItens.push(        
                    { label: 'Devices', icon: 'pi pi-fw pi-list', routerLink: [`/home/devices/${this.selectedEnterpriseName}`] }
            );
        }

        return model;
    }//end platformAdminMenu

    deviceOwnerMenu(): any[] {
        this.selectedEnterpriseName = this.dmpCommons.getDefaultEnterpriseName();

        let model:any[] = 
        [
            {
                label: 'IT Admin',
                items: [
                    { label: 'Devices', icon: 'pi pi-fw pi-list', routerLink: [`/home/devices/${this.selectedEnterpriseName}`] }
                ]
            },
        ];

        return model;
    }//end adminMenu


}//end AppMenuComponent


@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
      MessagesModule,
      CommonModule,
    ],
  })
  
  export class AppMenuModule {}
  