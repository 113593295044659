import { Injectable } from '@angular/core';
import { User } from '../models/user';
// cognito.service.ts
import { signIn, SignInInput } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})

export class CognitoService {
  /*
  PS: Not using this service right now to interact with cognito. Instead, using the "<amplify-authenticator>..." built-in component.
  */

  async handleSignIn({ username, password }: SignInInput) {
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
    } catch (error) {
      console.log('error signing in', error);
    }
  }
  
  async signIn(user: User): Promise<void> {

    const signInInputValue: SignInInput = {
      username: user.username,
      password: user.password,
      // other properties if needed
    };
    
    return this.handleSignIn(signInInputValue);
    
  }

  constructor() { }
}
