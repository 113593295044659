<app-dmp-commons></app-dmp-commons>
<ng-container *ngIf="authenticator.authStatus !== 'authenticated'">
    Not authenticated! <a routerLink="/sign-in">Sign In</a>
    <amplify-authenticator></amplify-authenticator>
  </ng-container>


    <!-- Only render this if there's an authenticated user -->
    <ng-container *ngIf="authenticator.authStatus === 'authenticated'">
        <h3>[mobile-app-install-component] Welcome, {{ authenticator.user.username }}!</h3>
    </ng-container>

    <p-toolbar>
        <div class="p-toolbar-group-left">

            <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="fullName" [filter]="true"
            filterBy="fullName"
            [showClear]="true"
            placeholder="Select a Country">
                <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="selectedCountry">
                        <div>{{selectedCountry.fullName}}</div>
                    </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.fullName}}</div>
                    </div>
                </ng-template>
                
            </p-dropdown>
            
        </div>

        <div class="p-toolbar-group-left">
            <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="fullName" [filter]="true" filterBy="fullName"
            [showClear]="true" placeholder="Select a Language">
                <ng-template language="selectedItem">
                    <div class="language-item language-item-value" *ngIf="selectedLanguage">
                        <div>{{selectedLanguage.fullName}}</div>
                    </div>
                </ng-template>
                <ng-template let-language pTemplate="item">
                    <div class="language-item">
                        <div>{{language.fullName}}</div>
                    </div>
                </ng-template>
            </p-dropdown>

        </div>

        <div class="p-toolbar-group-left" >
            <div class="input-container">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                </span>
                
                <input type="text"
                    class="p-inputtext p-component input-small"
                    style="width: 260px;"
                    [maxLength]="60"
                    [(ngModel)]="searchText" 
                    pInputText 
                    placeholder="Search Google Play App"
                    (keydown.enter)="handleClickSearchGooglePlay($event, 'v1')" #filter />

                <span class="p-input-icon-right" *ngIf="searchText">
                    <i class="pi pi-times-circle" (click)="clearSearch()"></i>
                </span>
            </div>

            <div class="p-toolbar-group-left">
                <button pButton pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-success m-2"
                (click)="handleClickSearchGooglePlay($event, 'v1')"
                >
                </button>
            </div>
        </div>
    </p-toolbar>


    <p-toolbar>
        <p-messages key="permanentMessages"></p-messages>
        <p-toast  key="shortMessages"></p-toast>
    </p-toolbar>
    <p>
    
    </p>

    <!-- INI CARD LIST -->
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <h5>Google Play Apps</h5>
                <p-dataView [value]="googleApps" layout="grid"  [emptyMessage]="emptyTableMessage">
                    <ng-template let-testApp pTemplate="gridItem">
                        <div class="grid grid-nogutter">
                            <div class="col-12 md:col-4" *ngFor="let googleApp of googleApps">
                                <div class="card m-3 border-1 surface-border">
                                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                                        <div class="flex align-items-center">
                                            <i class="pi pi-tag mr-2"></i>
                                            <span class="font-semibold">{{googleAppFormatPriceTag(googleApp)}}</span>
                                        </div>
                                        <span [class]="'product-badge status-instock'">{{googleApp.genre}}</span>
                                    </div>
                                    
                                    <div class="flex flex-column align-items-center text-center mb-3">
                                        <img [src]="googleApp.icon" [alt]="googleApp.title" class="w-9 shadow-2 my-3 mx-0"/>
                                        <div class="text-1xl font-bold">{{googleApp.title}}</div>
                                        <p-rating [ngModel]="googleApp.score" [readonly]="true" [cancel]="false"></p-rating>
                                    </div>

                                    <div class="align-items-center justify-content-between">
                                        <p-button 
                                        label="Details" 
                                        [icon]="'pi pi-info-circle'" 
                                        (click)="handleClickDetails($event, googleApp)" 
                                        class="w-100" 
                                        [style]="{ width: '100%' }" 
                                        [severity]="'primary'" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>


                </p-dataView>

            </div>
        </div>
    </div>
    <!-- END CARD LIST -->

    <!-- INI Modal Install -->
    <p-dialog 
    header="Header" 
    [(visible)]="modalInstallVisible" 
    [modal]="true" 
    [style]="{ width: '85%' }">
        <ng-template pTemplate="header">
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <p-image 
                    src="../../../../assets/layout/images/google-play.png" 
                    alt="Google Play Image"
                    width="30"
                    >
                </p-image>
                <span class="font-bold white-space-nowrap">
                    Google Play App Details
                </span>
            </div>
        </ng-template>

        <div class="grid grid-nogutter">


            <div class="col-12 md:col-6">
                <div class="card m-1 p-1 border-1 surface-border">
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                        <div class="flex align-items-center">
                            
                            <span style="font-size: 1.25rem !important; font-weight: 700 !important;">
                                {{googleAppFormatTitle(currentGoogleApp)}}
                            </span>

                        </div>
                    </div>




                    <div class="flex align-items-center">
                        <span style="font-size: 1.0rem !important; font-weight: 700 !important; color: green">

                            <div class="flex align-items-center">
                                <span class="font-semibold">{{googleAppFormatDeveloper(currentGoogleApp)}}</span>
                            </div>
                        </span>
                    </div>


                    <div class="flex align-items-center">
                        <span style="font-size: 1.0rem !important; font-weight: 700 !important;">
                            <div class="flex align-items-center">
                                <span class="font-bold">{{ googleAppFormatRating(currentGoogleApp) }}&nbsp;</span>
                                <i class="pi pi-star mr-2"></i>
                            </div>
                        </span>
                    </div>

                    <div class="flex align-items-center">
                        <span style="font-size: 1.0rem !important; font-weight: 700 !important;">

                            <div class="flex align-items-center">
                                <span class="font-semibold">{{googleAppFormatDownloads(currentGoogleApp)}}&nbsp;</span>
                            </div>
                        </span>
                    </div>

                    <div class="flex align-items-center">
                        <span style="font-size: 1.0rem !important; font-weight: 700 !important;">

                            <div class="flex align-items-center">
                                <span>
                                    {{googleAppFormatContentRating(currentGoogleApp)}}
                                </span>
                            </div>
                        </span>
                    </div>

                    <div class="flex align-items-center">
                        <span style="font-size: 1.0rem !important; font-weight: 700 !important;">

                            <div class="flex align-items-center">
                                <span>
                                    {{googleAppFormatPriceTag(currentGoogleApp)}}
                                </span>
                            </div>
                        </span>
                    </div>

                    <div class="flex align-items-center">
                        <span style="font-size: 1.0rem !important; font-weight: 700 !important;">

                            <div class="flex align-items-center">
                                <p-chip label="{{this.googleAppFormatInAppPurchase(currentGoogleApp)}}" styleClass="m-1 p-1"></p-chip>
                                <p-chip label="{{this.googleAppFormatContainAds(currentGoogleApp)}}" styleClass="m-1 p-1"></p-chip>
                            </div>
                        </span>
                    </div>

                </div>

            </div>

            <div class="col-4 md:col-4">
                
                    <img [src]="googleAppFormatIconUrl(currentGoogleApp)" [alt]="googleAppFormatTitle(currentGoogleApp)" class="m-0 p-1 w-9 shadow-2 my-0 mx-0"/>
                
            </div>



        </div>

        <div class="grid p-fluid">
            <div class="col-12">
                <div class="card">
                    <h5>Screenshots</h5>
                    <p-carousel [value]="googleAppImages" [numVisible]="3" [numScroll]="3" [circular]="false" [responsiveOptions]="carouselResponsiveOptions" >
                        <ng-template let-img pTemplate="item" style="padding: 1px; margin: 0px; border: 1px">
                                <div class="border-round m-0 text-center p-0" style="padding: 1px; margin: 0px; border: 1px solid var(--surface-d)">
                                    <div class="my-0" style="padding: 1px; margin: 1px; border: 1px;">
                                        <img [src]="img" alt="Google Play Screenshot" class="shadow-4" 
                                         style="padding: 1px; margin: 1px; border: 1px" width="85%"/>
                                    </div>

                                </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>

        <div class="flex align-items-center">
            <div class="card">
                <h5>Description</h5>
                <p-accordion>
                    <p-accordionTab header="Details" [selected]="true" class="line-height-3 m-0">
                        <div
                        class="content"
                        [innerHTML]="googleAppFormatDescription(currentGoogleApp)">
                        </div>
                    </p-accordionTab>

                </p-accordion>
            </div>
        </div>


        <ng-template pTemplate="footer">
            <p-button 
                label="Cancel" 
                [text]="true" 
                severity="secondary" 
                (onClick)="modalInstallVisible = false" />
            <p-button 
                type="button"
                label="Install" 
                pRipple
                
                severity="success" 
                (onClick)="handleClickInstall($event, currentGoogleApp)" 
              />
        </ng-template>
    </p-dialog>
    <!-- END Modal Install -->


    <p-toolbar >
        <div class="p-toolbar-group-right" >
            <button (click)="forceSignOut()">Sign Out</button>

        </div>
        
    </p-toolbar>