import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { from, Observable, take, timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TableComponentModule } from '../shared/ui/table.component'
import { fetchAuthSession } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import awsExports from '../../../../aws-exports';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CognitoService } from 'src/app/dmp/services/cognito.service';
import { AuthenticatorService, ForceNewPasswordComponent } from '@aws-amplify/ui-angular';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { Device } from 'src/app/dmp/api/device';
import { DeviceService } from 'src/app/dmp/services/device.service';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { Table } from 'primeng/table';
import { ViewEncapsulation} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MessagesModule } from 'primeng/messages';
import { FieldsetModule } from 'primeng/fieldset';
import { QRCodeModule } from 'angularx-qrcode';
import { ToastModule } from 'primeng/toast';
import { Message } from 'primeng/api';
import { DmpCommonsComponent } from '../shared/dmp-commons/dmp-commons.component';
import { EventSchema, MessageBrokerService } from '../../../layout/service/message.broker.service'; // Adjust the path as needed
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';

interface expandedRows {
  [key: string]: boolean;
}

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css', '../shared/dmp-commons/dmp-commons.component.css'],
  providers: [MessageService, ConfirmationService, DeviceService, DmpCommonsComponent, DialogModule, TooltipModule]
})

export class DeviceComponent implements OnInit {
  isPlatformAdmin$: Observable<boolean>;
  isDeviceOwner$: Observable<boolean>;

  labelLockDevice: string = "Lock Device";
  labelLockDeviceOriginal: string = this.labelLockDevice;
  processingLockDevice: boolean = false;

  labelWipe: string = "Detach Device";
  labelWipeOriginal: string = this.labelWipe;
  processingWipe: boolean = false;

  labelNewRegistration: string = "New Device Registration";
  labelNewRegistrationOriginal: string = this.labelNewRegistration;
  processingNewRegistration: boolean = false;

  messagesVisibility: string = "none";

  isDeviceFilterVisible: boolean = false;

  newLockPassword: string = "";
  newLockPasswordVerification: string = "";
  showLockDialog: boolean = false;
  submitted: boolean = false;
  
  deviceSetupVisibility = "none";
  deviceEnrollmentLink = "";

  //#region Table Properties
  emptyTableMessage: string = "";

  devices: Device[] = [];

  rowGroupMetadata: any;

  expandedRows: expandedRows = {};

  activityValues: number[] = [0, 100];

  isExpanded: boolean = false;

  idFrozen: boolean = false;

  loading: boolean = true;

  @ViewChild('filter') filter!: ElementRef;
  selectedDevice: any = undefined;
  selectedDeviceId: any = undefined;
  selectedPolicyId: any = undefined;
  selectedIsPendingRegistration: boolean = true;
  selectedDeviceFriendlyName: any = undefined;
  selectedDeviceName: any;
  selectedDeviceDisplay: string = "";

  @ViewChild('mainTable') table!: Table;
  //#endregion

  authService = null;

  public newDeviceRegistrationQrCode: string = "";
  public newDeviceExtraParams: string = "playStoreMode=WHITELIST,defaultPermissionPolicy=GRANT";

  constructor( 
  private messageBrokerService: MessageBrokerService,
  public dmpCommons: DmpCommonsComponent,
  private messageService: MessageService, 
  private activatedRoute: ActivatedRoute,
  private router:Router, 
  private cognitoService: CognitoService,
  private deviceService: DeviceService,
  public authenticator: AuthenticatorService)
  { 
    Amplify.configure(awsExports);

    this.authenticator = authenticator;
  }

  async currentSession() {
        
    try {
        let authSessionTokens = (await fetchAuthSession()).tokens ?? {};
        
        return authSessionTokens;
    } catch (err) {
        console.log(err);
    }
    
    return undefined;
  }


  enterpriseId?:string;
  ngOnInit() {
    this.isPlatformAdmin$ = this.isUserPlatformAdmin();
    this.isDeviceOwner$ = this.isUserDeviceOwner();

    //this.messagesVisibility = "none";

    //#region params
    this.enterpriseId = this.activatedRoute.snapshot.paramMap.get('enterpriseId') ?? undefined;
    //#endregion
    
    //#region service data
    this.loadTable();
    //#endregion

  }//end ngOnInit

  formatDeviceState(deviceIsPendingRegistration: string): string {
    let parsedIsPendingRegistration = this.dmpCommons.parseBoolean(deviceIsPendingRegistration);
    let deviceState = "Registered";
    if (parsedIsPendingRegistration) {
      deviceState = "Pending Registration";
    }
    return deviceState;
  }//end formatDeviceState


  isUserDeviceOwner(): Observable<boolean> {
    //console.log("!!!!!!! isUserDeviceOwner");
    let promise = this.dmpCommons.hasRoleDeviceOwner();
    return from(promise);
  }//end isUserPlatformAdmin

  isUserPlatformAdmin(): Observable<boolean> {
    //console.log("!!!!!!! isUserPlatformAdmin");
    return from(this.dmpCommons.hasRolePlatformAdmin());
  }//end isUserPlatformAdmin

  private loadTable() {

    if (this.enterpriseId == undefined) {
      console.log("enterpriseId is undefined");
      return;
    }

    this.currentSession().then((authSessionTokens: any) => {
      this.emptyTableMessage = "Loading...";
      let idToken: string = authSessionTokens.idToken.toString();

      //TO-DO: CALL THE SERVICE HERE
      let enterpriseId = this.enterpriseId ?? '';
      this.deviceService.getDevices(idToken, enterpriseId).subscribe({
        next: devicesResponse => {
          let responseHasError = this.dmpCommons.handleResponseWithMessage(
            devicesResponse, 
            undefined, 
            undefined, 
            true, 
            this.messageService);
          if (!responseHasError) {
            this.devices = devicesResponse.data;
            console.log("this.devices.length: " + this.devices.length);
            if (this.devices.length == 0) {
              this.emptyTableMessage = "No devices found";
            }//end if
          }else{
            this.messagesVisibility = "block";
            this.emptyTableMessage = "Load failed, try again later...";
          }

        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching devices:', error);
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error fetching devices" });
        }
      });

    })
      .catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession():', error);
      });
  }

  //#endregion

  private rowSelectAction(device: Device) {
    let messageContent: EventSchema = new EventSchema();
    let eventValue: Device = device;

    messageContent.key = 'device-selected';
    messageContent.value = eventValue;
    console.log("rowSelectAction: messageContent.key: " + messageContent.key);
    console.log("rowSelectAction: messageContent.value: " + messageContent.value);
    this.messageBrokerService.sendEventMessage(messageContent);
  
    console.log("rowSelectAction: this.selectedDeviceName: " + device.name);
    console.log("rowSelectAction: this.friendlyName: " + device.friendlyName);

    this.selectedDeviceName = device.name;
    this.selectedDeviceFriendlyName = device.friendlyName;
    
    this.selectedDeviceDisplay = `Selected: ${device.friendlyName}`;
    
    this.isDeviceFilterVisible = true;

    //this.changeDetector.detectChanges();
    //console.log("this.changeDetector.detectChanges()");
  }

  //#region cognito
  forceSignOut () {
    this.authenticator.signOut();
    this.router.navigate(['/sign-in']);
  }
  
  //#endregion

  //#region Table Methods
  onRowSelect(event: any) { 
    console.log(`onRowSelect.type: ${event.type}`);
    console.log(`onRowSelect.originalEvent: ${event.originalEvent}`);

    let device = event.data
    console.log(`onRowSelect: device.isPendingRegistration = ${device.isPendingRegistration}`);

    this.selectedDevice = device;
    this.selectedDeviceId = device.name;
    this.selectedPolicyId = device.appliedPolicyName;
    this.selectedIsPendingRegistration = this.dmpCommons.parseBoolean(device.isPendingRegistration);
    console.log("onRowSelect event: device.name: " + device.name);
    console.log("onRowSelect event: device.selectedPolicyId: " + device.appliedPolicyName);

    this.rowSelectAction(device);

    DmpCommonsComponent.saveLocalCache(this.dmpCommons.getUrnSelectedDeviceName(), device.name);
  } 
  
  onRowUnselect(event: any) { 
    console.log("onRowUnselect event");

    this.selectedDeviceName = undefined;
    this.selectedDeviceDisplay = ``;
    this.selectedDeviceId = undefined;
    this.selectedPolicyId = undefined;
    this.selectedIsPendingRegistration = false;

    this.clearFilter();
  } 

  formatDeviceNetworkOperator(device: any): string {
    let emmDeviceId = device.emmDeviceId;
    console.log("emmDeviceId: " + emmDeviceId);
    let deviceNetworkOperator = device.networkInfo.networkOperatorName;

    if (this.dmpCommons.isEmptyString(emmDeviceId)) {
      deviceNetworkOperator = "n/a";
    }else{
      if (this.dmpCommons.isEmptyString(deviceNetworkOperator)) {
        deviceNetworkOperator = "Not provided by device";
      }
    }//end if
    return deviceNetworkOperator;
  }

  formatDeviceName(manufacturer: string, brand: string, model: string): string {
    return this.dmpCommons.formatDeviceName(manufacturer, brand, model);
  }

  onButtonFilterDeviceClose(event: any) {
    console.log('Button Message closed:', event);
    this.clearFilter();
    // Your custom logic here, e.g., reset the message array or perform some cleanup
  }

  clearFilter() {
    this.selectedDeviceId = undefined;
    this.selectedDeviceName = undefined;
    this.selectedDeviceDisplay = ``;

    let messageContent: EventSchema = new EventSchema();
    messageContent.key = 'device-unselected';
    this.messageBrokerService.sendEventMessage(messageContent);
    this.isDeviceFilterVisible = false;
  }

  formatCurrency(value: number) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  onGlobalFilter(table: Table, event: Event) {
      table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
      table.clear();
      //this.filter.nativeElement.value = '';
  }

  newDeviceService() {
    this.setButtonNewRegistrationProcessingStatus();

    this.currentSession().then((authSessionTokens: any) => {
     
      let idToken:string = authSessionTokens.idToken.toString();
      
      let enterpriseId = this.enterpriseId ?? '';
      let policyId = "defaultPolicy"; // This will be ignored by the backend
      let extraParams = this.newDeviceExtraParams ?? "";

      this.deviceService.createEnrollmentLink(idToken, enterpriseId, policyId, extraParams).subscribe({
        next: enrollmentLinkResponse => {
          console.log(`About to create an enrollment link for enterpriseId: ${enterpriseId}, policyId: ${policyId}`);

          //handle error/warn/info in standard response
          let summaryMessage = `New Device Link Created !`;
          let detailsMessage = `From your device, click the link ( or use qrcode ) provided. From there, follow instructions on your device.`;

          this.deviceSetupVisibility = "block";
          this.messagesVisibility = "block";
          let responseHasError = this.dmpCommons.handleResponseWithMessage(
            enrollmentLinkResponse, 
            summaryMessage, 
            detailsMessage, 
            true, 
            this.messageService);
          if (!responseHasError) {
            this.clearFilter();
            this.refreshTable();
          }//end if

          this.enableButtonNewRegistration();

          if (!responseHasError) {
            this.deviceEnrollmentLink = enrollmentLinkResponse.data['enrollment_link'];
            
            this.newDeviceRegistrationQrCode = this.deviceEnrollmentLink;

            timer(90000).subscribe(() => {
              this.refreshTable();
            });
          }//end if

        },
        error: error => {
          // Handle error if needed
          console.error('Error creating enrollment link:', error);
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error creating enrollment link !" });
          this.enableButtonNewRegistration();
        }
      });
  
    })
    .catch(error => {
        console.error('Error fetching currentSession() to get enterprises:', error);
        this.messagesVisibility = "block";
        this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error fetching session !" });
        this.enableButtonNewRegistration();
    });
  }

  handleClickRefresh(event: any) {
    this.onRowUnselect(event);

    this.refreshTable();
  }

  private refreshTable() {
    //this.filter.nativeElement.value = "";
    //this.searchText = "";
    
    this.emptyTableMessage = "Refreshing...";

    this.clear(this.table);
    
    this.devices = [];

    setTimeout(() => {
      this.loadTable();
    }, 2000);
  }

  handleClickNewDevice(event: any) {
    this.newDeviceService();
  }

  handleClickCancelLockDialog(event: any) {
    console.log("handleClickCancelLockDialog event: " + event);
    this.showLockDialog = false;
  }

  handleClickLockDevice(event: any) {
    console.log("handleClickLockDevice event: " + event);

    if (this.selectedDeviceId == null || this.selectedDeviceId == undefined) {
      console.log("handleClickApps event: Device is undefined: select one");
      return;
    }

    let keyValuePairDeviceId = this.selectedDeviceId.split("/");
    let enterpriseId = keyValuePairDeviceId[1];

    let deviceId = this.selectedDevice.deviceId;

    this.lockDevice(enterpriseId, deviceId, undefined);

  }

  handleClickConfirmLockDialog(event: any) {
    //chaging password is not allowed in personally owned devices
    //to-do: check if both passwords is equal
    console.log("handleClickConfirmLockDialog event: " + event);

    console.log("handleClickApps selectedDeviceId: " + this.selectedDeviceId);
    console.log("handleClickApps selectedPolicyId: " + this.selectedPolicyId);
    console.log("handleClickApps event: " + event);

    let newPassword = undefined;

    if (this.newLockPassword != undefined) {
      newPassword = this.newLockPassword;
    }

    let keyValuePairDeviceId = this.selectedDeviceId.split("/");
    let enterpriseId = keyValuePairDeviceId[1];
    let deviceId = keyValuePairDeviceId[3];

    this.lockDevice(enterpriseId, deviceId, newPassword);
    this.showLockDialog = false;
  }

  handleClickApps(event: any) {
    if (this.selectedDeviceId == null || this.selectedDeviceId == undefined || this.selectedIsPendingRegistration ) {
      console.log("handleClickApps event: Device is undefined: select one");
      return;
    }
    console.log("handleClickApps selectedDeviceId: " + this.selectedDeviceId);
    console.log("handleClickApps selectedPolicyId: " + this.selectedPolicyId);
    console.log("handleClickApps event: " + event);
    
    let keyValuePairDeviceId = this.selectedDeviceId.split("/");
    let enterpriseId = keyValuePairDeviceId[1];
    let emmDeviceId = keyValuePairDeviceId[3];

    let keyValuePairPolicyId = this.selectedPolicyId.split("/");
    let policyId = keyValuePairPolicyId[3];
    
    let deviceId = this.selectedDevice.deviceId;

    console.log(`Navigating to apps with enterpriseId: ${enterpriseId}, emmDeviceId: ${emmDeviceId}, policyId: ${policyId}, deviceId: ${deviceId}` );

    this.router.navigate(['home/apps', enterpriseId, emmDeviceId, policyId, deviceId]);
    //this.router.navigate(['home/apps', enterpriseId, emmDeviceId, policyId]);
  }//end handleClickApps

  handleClickLockDeviceWithPassword(event: any) {
    //Chaging password is not allowed in personally owned devices

    if (this.selectedDeviceId == null || this.selectedDeviceId == undefined) {
      console.log("handleClickApps event: Device is undefined: select one");
      return;
    }
    this.showLockDialog = true;
  }

  handleClickRemoveDevice(event: any) {

    if (this.selectedDeviceId == null || this.selectedDeviceId == undefined) {
      console.log("handleClickRemoveDevice event: Device is undefined: select one");
      return;
    }
    console.log("handleClickRemoveDevice event: " + this.selectedDeviceId);

    let enterpriseId = this.enterpriseId ?? '';

    //let keyValuePairDeviceId = this.selectedDeviceId.split("/");
    //let deviceId = keyValuePairDeviceId[3];

    //service remove device. Chamar nos mesmos moldes do deleteApp
    let deviceId = this.selectedDevice.deviceId;
    console.log("handleClickRemoveDevice deviceId ( from db ): " + deviceId);
    this.deleteDevice(enterpriseId, deviceId);
  }

  lockDevice(enterpriseId: string, deviceId: string, newPassword: string | undefined) {
    this.setButtonLockDeviceProcessingStatus();

    this.currentSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();
      
      this.deviceService.lockDevice(idToken, enterpriseId, deviceId, newPassword).subscribe({
        next: response => {
          this.messagesVisibility = "block";
          let responseHasError = this.dmpCommons.handleResponseWithMessage(response, 'Device Successfully Locked !', undefined, true, this.messageService);

          if (!responseHasError) {
            this.enableButtonLockDevice();
          }//end if
        },
        error: error => {
          console.error('Error requesting device lock:', error);

          this.enableButtonLockDevice();

          this.messagesVisibility = "block";
          this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error requesting device lock !" });
        }
      });
    })
    .catch(error => {
      console.error('Error requesting session for device lock:', error);

      this.enableButtonLockDevice();

      this.messagesVisibility = "block";
      this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error requesting session for device lock !" });

    });
    
  }

  mustHideButtonLockDevice(): boolean {
    return !this.selectedDeviceId
     || this.processingLockDevice || this.selectedIsPendingRegistration
  }//end mustHideButtonUninstallApp

  mustHideButtonListApplications(): boolean {
    return !this.selectedDeviceId
     || this.processingLockDevice || this.selectedIsPendingRegistration
  }//end mustHideButtonUninstallApp

  mustHideButtonWipe(): boolean {
    return !this.selectedDeviceId
     || this.processingWipe
  }//end mustHideButtonUninstallApp

  mustHideButtonNewRegistration(): boolean {
    return this.processingNewRegistration
  }//end mustHideButtonUninstallApp

  private setButtonLockDeviceProcessingStatus() {
    this.processingLockDevice = true;
    this.labelLockDevice = "Processing...";

    //Caso não retorne em 10 segundos, habilitar o botão novamente
    timer(10000).subscribe(() => {
      this.enableButtonLockDevice();
    });
  }//end function

  private setButtonWipeProcessingStatus() {
    this.processingWipe = true;
    this.labelWipe = "Processing...";

    //Caso não retorne em 10 segundos, habilitar o botão novamente
    timer(10000).subscribe(() => {
      this.enableButtonWipe();
    });
  }//end function

  private setButtonNewRegistrationProcessingStatus() {
    this.processingNewRegistration = true;
    this.labelNewRegistration = "Processing...";

    //Caso não retorne em 10 segundos, habilitar o botão novamente
    timer(10000).subscribe(() => {
      this.enableButtonNewRegistration();
    });
  }//end function

  private enableButtonLockDevice() {
    this.labelLockDevice = this.labelLockDeviceOriginal;
    this.processingLockDevice = false;
  }//end function

  private enableButtonNewRegistration() {
    this.labelNewRegistration = this.labelNewRegistrationOriginal;
    this.processingNewRegistration = false;
  }//end function

  private enableButtonWipe() {
    this.labelWipe = this.labelWipeOriginal;
    this.processingWipe = false;
  }//end function

  formatDatetime(originalDatetimeStr: string): string {
    return this.dmpCommons.formatDatetime(originalDatetimeStr);
  }

  deleteDevice(enterpriseId: string, deviceId: string) {
    this.setButtonWipeProcessingStatus();

    this.currentSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();
      
      this.deviceService.deleteDevice(idToken, enterpriseId, deviceId).subscribe({
        next: response => {
          //handle error/warn/info in standard response
          this.messagesVisibility = "block";
          let responseHasError = this.dmpCommons.handleResponseWithMessage(
            response, 
            'Successfully Wiped', 
            'Device Data on Protected Profile Successfully Wiped !', 
            true, 
            this.messageService);
          if (!responseHasError) {
            this.clearFilter();
            this.refreshTable();
          }//end if

          this.enableButtonWipe();
        },
        error: error => {
          console.error('Error wiping data:', error);
          //this.messageService.add({ key: 'shortMessages', severity: 'error', summary: 'Error Wiping Data', detail: 'Error Wiping Device Data on Protected Profile !' } );
          this.messagesVisibility = "block";
          this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error Wiping Device Data on Protected Profile !" });
          
          this.enableButtonWipe();
        }
      });
  
    })
    .catch(error => {
      this.messagesVisibility = "block";
      this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "Error Wiping Device Data on Protected Profile !" });
      console.error('Error fetching currentSession() to wiping device data:', error);
      
      this.enableButtonWipe();
    });
    
  }


  //#endregion


}

@NgModule({
  declarations: [DeviceComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    DividerModule,
    DialogModule,
    FormsModule,
    ToastModule,
    QRCodeModule,
    SharedModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    CommonModule,
    ButtonModule,
    MessagesModule,
    FieldsetModule,
    TableComponentModule,
    RouterModule.forChild([
        {
            path: '',
            component: DeviceComponent,
        },
    ]),
    DmpCommonsComponent
],
})

export class DeviceModule {}


