<app-dmp-commons></app-dmp-commons>
    <ng-container *ngIf="! dmpCommons.isAuthenticated()">
        Not authenticated! <a routerLink="/sign-in">Sign In</a>
        <amplify-authenticator></amplify-authenticator>
    </ng-container>

    <!-- Only render this if there's an authenticated user -->
    <!--
    <ng-container *ngIf="dmpCommons.isAuthenticated()">
        <h3>[enterprise.component] Welcome, {{ dmpCommons.userName() }} from group {{this.userGroups.toString()}} ! </h3>
        <h3>Environment: {{ environmentName }}</h3>
    </ng-container>
    -->


    <p-toolbar [ngStyle]="{display: enterpriseSetupVisibility}">
        <p-card header="Simple Setup Process Started">
            <p class="m-0">
                Visit this link with your google account and follow instructions: <a href="{{ enterpriseSignupLink }}">Initial Setup Link</a>
                <br>Or scan the QR code below from another device:<br> 
                <qrcode [qrdata]="newEnterpriseSetupQrCode" [width]="256" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
            </p>
        </p-card>
    </p-toolbar>

    <p-toolbar [ngStyle]="{display: messagesVisibility}">
        <p-messages key="permanentMessages"></p-messages>
        <p-toast key="shortMessages"></p-toast>
    </p-toolbar>

    <p-toolbar>

        <div class="p-toolbar-group-start">
            <p-button icon="pi" styleClass="p-button-info" label="User Onboarding" (click)="handleClickNewEnterprise($event)"></p-button>

            &nbsp;&nbsp;&nbsp;&nbsp;<p-button [disabled]="!selectedEnterpriseName" icon="pi" styleClass="p-button-danger" label="Delete Enterprise" (click)="handleClickDeleteEnterprise($event)"></p-button>
        </div>

        <div class="p-toolbar-group-end">
            <p-button [disabled]="!selectedEnterpriseName" icon="pi" styleClass="p-button-success" label="List Devices" (click)="handleClickDevices($event)"></p-button>

        </div>
        
        <div class="p-toolbar-group-end" >
            <p-button icon="pi pi-refresh" (click)="handleClickRefresh($event)"></p-button>
        </div>
    </p-toolbar>

    <p-table 
    [value]="enterprises" 
    selectionMode="single" 
    dataKey="name" 
    [(selection)]="selectedEnterpriseName"
    [tableStyle]="{'min-width': '50rem'}"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <div class="table-title">
                    Enterprises
                    &nbsp; &nbsp; &nbsp;
                </div>

            </div>
            <div class="p-toolbar-group-left">
                <p-button 
                class="filter-button"
                key="enterpriseFilter"
                (onClick)="onButtonEnterpriseClose($event)"
                [hidden]="!isEnterpriseFilterVisible" 
                [raised]="true"
                severity="secondary" [text]="true"
                [label]="this.selectedEnterpriseDisplay" icon="pi pi-times" iconPos="right" />
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>&nbsp;</th>
                <th>name</th>
                <th>enterpriseDisplayName</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-enterprise>
            <tr [pSelectableRow]="enterprise">
                <td>
                    <p-tableRadioButton 
                    [value]="enterprise"> 
                    </p-tableRadioButton>
                    
                </td>
                <td>{{enterprise.name}}</td>
                <td>{{enterprise.enterpriseDisplayName}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="16">{{emptyTableMessage}}</td>
            </tr>
        </ng-template>
    </p-table>

    


    <p>
    <button (click)="forceSignOut()">Sign Out</button>
    </p>
