import { Component, OnInit } from '@angular/core';
import { ConfirmEventType, ConfirmationService, MessageService,  } from 'primeng/api';
import { RemoteActionService } from '../../services/remote.action.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { UUID } from "angular2-uuid";
import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import awsExports from '../../../../aws-exports';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css', ],
    providers: [ConfirmationService,MessageService,RemoteActionService,MessageService]
})

export class HomeComponent implements OnInit {
    requestIdQueue!: Map<string, any>;
    static requestIdQueueSemaphore: boolean;
    requestIdQueueMaxRetry!: number;

    pwdActionForceWipe: string = '';

    secretKey: string = '';
    initializationVector: string = '';
    clientKey: string = '';
    clientSecret: string = '';
    isClientKeyDisabled: boolean = false;
    isClientSecretDisabled: boolean = false;
    isButtonAcceptKeyDisabled: boolean = false;
    
    constructor(
        private remoteActionService: RemoteActionService, 
        private messageService: MessageService, 
        private confirmationService: ConfirmationService,
        public authenticator: AuthenticatorService) { 
            Amplify.configure(awsExports);
        }

    async currentSession() {
        
        try {
            let authSessionTokens = (await fetchAuthSession()).tokens ?? {};
            
            return authSessionTokens;
        } catch (err) {
            console.log(err);
        }
        
        return undefined;
    }
        
    ngOnInit() {
        this.requestIdQueueMaxRetry = 4;
        HomeComponent.requestIdQueueSemaphore = false;
        this.requestIdQueue = new Map<string, any>();

        // Start executing the method every 5 seconds
        const intervalId = setInterval(() => {
            this.requestIdQueueProcessor();
        }, 5000); // 5000 milliseconds (5 seconds)
        
    }

    acceptSecretKey(key: string, secret: string) {
        this.secretKey = key;
        this.initializationVector = secret;
        this.clientKey = "*****************";
        this.clientSecret = "*****************";
        this.isClientKeyDisabled = true;
        this.isClientSecretDisabled = true;
        this.isButtonAcceptKeyDisabled = true;
    }

    requestIdQueueProcessor() {
        if (HomeComponent.requestIdQueueSemaphore) return;

        HomeComponent.requestIdQueueSemaphore = true;

        if (this.requestIdQueue.size < 1) {
          HomeComponent.requestIdQueueSemaphore = false;
            return;
        }
        

        let requestId = "";

        let idToken = "";

        this.currentSession().then((authSessionTokens: any) => {
            idToken = authSessionTokens.idToken.toString();
        })
        .catch(error => {
            console.error('Error fetching currentSession() in requestIdQueueProcessor():', error);
        });
        
        //get id status de cada item
        this.requestIdQueue.forEach((value, key) => {
            let retriesLeft = parseInt(value);
            requestId = key;
            console.log("Verificando status de " + requestId);

            //verifica status do id da requisicao
            this.remoteActionService.getActionState(requestId, idToken).subscribe({
                next: (response: HttpResponse<any>) => {
                    try {
                        console.info('next, sucesso');
                        let responseText = response.toString();
                        console.info("responseText: " + responseText)

                        //msg de sucesso, se done
                        let timestampString = this.getDateTimeStamp();
                        
                        let summaryMessage = ""

                        let detailsMessage = ""
                        

                        //responseText="no response"; //testing no responses

                        if (responseText=="no response") {
                            //Nao retornou valor, decrementa o retry
                            retriesLeft--;
                            summaryMessage = timestampString + " => " + "getstatus requested!"
                            
                            this.requestIdQueue.set(requestId, retriesLeft); 

                            if (retriesLeft > 1) {
                                detailsMessage = "id="+requestId
                                detailsMessage += ". Waiting response, see the next messages below.. "
                                detailsMessage += retriesLeft + " retries remaining"
                            }else if (retriesLeft == 1) {
                                detailsMessage = "id="+requestId
                                detailsMessage += ". Waiting response, see the next messages below.. "
                                detailsMessage += retriesLeft + " retry left"
                            }else{
                                let urlCheck = 'https://api.webteclife.com.br/remote-device-actions?id='+requestId
                                detailsMessage += ". No more retry"
                                detailsMessage += ". Api Route " + urlCheck
                                this.requestIdQueue.delete(requestId);
                            }

                            this.messageService.add({severity:'info', summary:summaryMessage, detail:detailsMessage});
                        }else{
                            //Retornou valor, remove id da fila se sucesso
                            console.info('remove id da fila');
                            
                            summaryMessage = timestampString + " => " + "getstatus returned!"

                            this.requestIdQueue.delete(requestId);

                            responseText = response.toString()
                            var state = (response as any)["state"]
                            var action = (response as any)["action"]
                            detailsMessage += ". Response: action=" + action + ", state=" + state
                            this.messageService.add({severity:'success', summary:summaryMessage, detail:detailsMessage});
                        }
                        
                    } catch (error) {
                        console.info('erro, detalhes em: ' + error);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    console.info('Erro, detalhes abaixo:');
                    console.info('e.status=', error.status);
                    console.info('e.statusText=', error.statusText);
                    console.info('e.name=', error.name);
                    console.info('e.message=', error.message);
                    let summaryMessage = "http-status=" + error.status + ". " + error.message
                    //let urlCheck = '<a href="https://api.webteclife.com.br/remote-device-actions?id='+requestId+'" target="_blank">status</a>'
                    let urlCheck = 'https://api.webteclife.com.br/remote-device-actions?id='+requestId
                    let detailsMessage = " try " + urlCheck
                    this.messageService.add({severity:'error', summary:summaryMessage, detail:detailsMessage});
                    console.error(error);
                },
                complete: () => {
                    console.info('complete');
                  }
    
            });

        });

        HomeComponent.requestIdQueueSemaphore = false;
    }

    raiseCommandAws(actionName: string) {
        console.info("raiseCommandAws("+actionName+")");

        this.currentSession().then((authSessionTokens: any) => {
            this.executeDeviceCommand(actionName, authSessionTokens.idToken);
        })
        .catch(error => {
            console.error('Error fetching currentSession():', error);
        });


        
    }

    private executeDeviceCommand(actionName: any, idToken: string) {
        let actionNameDisplay = actionName;
        if (actionName.startsWith("forcewipe|")) {
            actionNameDisplay = ("forcewipe|********************");
        }

        let timestampString = this.getDateTimeStamp();
        let requestId = UUID.UUID();
        
        this.remoteActionService.remoteActionUsingAWSUsingCognito(idToken, actionName, requestId).subscribe({
            next: (response: HttpResponse<any>) => {
                //Trying to figureout error, since the response.status is always undefined even when http code is 200
                let probableResponseError = false;
                if (response.status != 200) probableResponseError = true;

                const responseErrorPattern = /err|Error|forbidden|invalid/;
                let errorMessage = "";
                let messageSeverity = "success";

                if (responseErrorPattern.test(response.body) ) {
                    probableResponseError = true;
                    errorMessage = response.body;
                    messageSeverity = "error";
                }
                
                console.info('next, sucesso, response.body=' + response.body);
                console.info('next, sucesso, response.status=' + response.status);
                console.info('next, sucesso, response.statusText=' + response.statusText);
                let retries = this.requestIdQueueMaxRetry;
                this.requestIdQueue.set(requestId, retries);
                let summaryMessage = timestampString + " => " + actionNameDisplay + " requested!";
                let detailsMessage = "id=" + requestId + ". details="+response.body;
                //let urlCheck = 'https://api.webteclife.com.br/remote-device-actions?id='+requestId
                //detailsMessage += " .check-url=" + urlCheck

                this.messageService.add({ severity: messageSeverity, summary: summaryMessage, detail: detailsMessage });
                this.requestIdQueueProcessor();
                console.info(response);

            },
            error: (error: HttpErrorResponse) => {
                let summaryMessage = error.message;
                let detailsMessage = "http-status=" + error.status;
                this.messageService.add({ severity: 'error', summary: summaryMessage, detail: detailsMessage });
                try {
                    console.debug('Erro, detalhes abaixo:');
                    console.debug('e.status=', error.status);
                    console.debug('e.statusText=', error.statusText);
                    console.debug('e.name=', error.name);
                    console.debug('e.message=', error.message);
                    console.debug('e.error=', error.error);
                    console.debug('e.error.message=', error.error.message);
                } catch (nestedError) {
                    console.error('Erro ao obter detalhes do retorno');
                }

                console.error(error);
            },
            complete: () => {
                console.info('complete');
            }
        });
    }

    private getDateTimeStamp() {
        let saoPauloTimeZone = 'America/Sao_Paulo'; // Brasília Time (UTC-3)
        let currentDateTime = new Date();

        var formattedTimestamp = this.formatDateTime(new Date());
        
        return formattedTimestamp;
    }

    private formatDateTime(currentDateTime: Date) {
      const saoPauloTimeZone = 'America/Sao_Paulo';
      
      // Create an Intl.DateTimeFormat object with the desired time zone
      const dateTimeFormatter = new Intl.DateTimeFormat('pt-BR', {
        timeZone: saoPauloTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      
      // Format the current date and time
      const formattedTimestamp = dateTimeFormatter.format(currentDateTime);

      return formattedTimestamp;
    }

    confirmRaiseCommandAws(actionName: string) {
        let actionNameDisplay = actionName;
        if (actionName=="forcewipe") {
            //actionName="forcewipe-SIMULATION" //Comment HERE ON PRODUCTION !!!
            actionName += ("|" + this.pwdActionForceWipe);
            actionNameDisplay += ("|********************");
            this.pwdActionForceWipe = "******************************************";
        }

        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: actionNameDisplay + " request confirmed by user" });

                this.currentSession().then((authSessionTokens: any) => {
                    //console.info("currentSession() idToken = " + authSessionTokens.idToken);
                    this.executeDeviceCommand(actionName, authSessionTokens.idToken);
                })
                .catch(error => {
                    console.error('Error fetching currentSession():', error);
                });
                
                
            },
            reject: (type: ConfirmEventType) => {
                if (type==ConfirmEventType.REJECT) {
                    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: actionNameDisplay + " request rejected by user" });
                }else if (type==ConfirmEventType.CANCEL) {
                    this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: actionNameDisplay + " request cancelled by user" });
                }
            }//reject
        });//confirm
    }

}
