{
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_8ct9HcCKp",
  "aws_user_pools_web_client_id": "33etvskuqdc84lc4q375sidi7p",
  "oauth": {
    "domain": "devicecontrolamplifyoauth-dev.auth.us-east-1.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://redirectSignInUrl/",
    "redirectSignOut": "https://redirectSignOutUrl/",
    "responseType": "token"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OPTIONAL",
  "aws_cognito_mfa_types": [
    "TOTP"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "PHONE_NUMBER"
  ]
}