import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MobileApp } from '../api/mobile-app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';

@Injectable()
export class MobileAppService {
  constructor(private http: HttpClient) { }

  reenableApp(idToken: string, enterpriseId: string, policyId: string, deviceId: string, packageName: string) {
      let action_name = "reactivate-app";

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };

      const body = {
        action: action_name,
        enterprise_id: enterpriseId,
        policy_id: policyId,
        device_id: deviceId,
        package_name: packageName
      };
  
      let basePath = environment.backendBaseURL;
      let path = "/v1/app-actions";

      console.log(`about to re-enable-app: ${enterpriseId}, policyId: ${policyId}, packageName: ${packageName}, idToken: ${idToken}`)

      let response = this.http.post(`${basePath}${path}`, body, httpOptions);

      console.log(`response: ${response}`);
  
      return response;
    }//end removeAllApps

    removeAllApps(idToken: string, enterpriseId: string, policyId: string, deviceId: string, packageName: string) {
      let action_name = "remove-all-apps";

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };

      const body = {
        action: action_name,
        enterprise_id: enterpriseId,
        policy_id: policyId,
        device_id: deviceId,
        package_name: packageName
      };
  
      let basePath = environment.backendBaseURL;
      let path = "/v1/app-actions";

      console.log(`about to remove all apps: ${enterpriseId}, policyId: ${policyId}, idToken: ${idToken}`)

      let response = this.http.post(`${basePath}${path}`, body, httpOptions);

      console.log(`response: ${response}`);
  
      return response;
    }//end removeAllApps

    getApps(idToken: string, enterpriseId: string, deviceId: string): Observable<any> {

        const httpOptions = {
          headers: { 
            'Content-Type': 'application/json',
            'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': idToken,
            'x-api-key': environment.xApiKey
         },
          params: {
            'enterprise_id': enterpriseId,
            'device_id': deviceId
          }
        };

        let basePath = environment.backendBaseURL;
        let path = "/v1/apps";
        console.info("idToken", idToken);
        console.info("xApiKey", environment.xApiKey);

        console.log("about to get apps with httpOptions: ", basePath, path, enterpriseId, deviceId);

        let response = this.http.get(`${basePath}${path}`, httpOptions);

        return response;
    }
    
    deleteApp(idToken: string, enterpriseId: string, policyId: string, deviceId: string, packageName: string, justDisable: boolean): Observable<any> {
      /*
      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'Access-Control-Allow-Origin': '*',  //https://dmp.webteclife.com.br
          'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,Content-Type,Cache-Control,Pragma,Expires',
          'Access-Control-Allow-Methods': 'DELETE, OPTIONS, GET',
          'Access-Control-Allow-Credentials': 'false' 
        }
      };
      */

      const httpOptions = {
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': idToken,
          'x-api-key': environment.xApiKey
        }
      };
  
      let basePath = environment.backendBaseURL;
      let path = `/v1/apps?enterprise_id=${enterpriseId}&policy_id=${policyId}&device_id=${deviceId}&package_name=${packageName}&just_disable=${justDisable}`;

      console.log(`about to delete app with path: ${path}, idToken: ${idToken}`)
  
      let response = this.http.delete(`${basePath}${path}`, httpOptions);

      return response;
  }

    getAppsMock(idToken: string, enterpriseId: string): Observable<any> {
      return this.http.get<any>('../../assets/mock/data/mobile-apps.json');
    }

}
